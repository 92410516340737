import {
  GET_ITINERAIRE,
  ADD_ITINERAIRE,
  EDIT_ITINERAIRE,
  SET_ITINERAIRE_DETAILS,
  DELETE_ITINERAIRE,
} from "@jumbo/constants/ActionTypes";

const INIT_STATE = {
  itineraires: [
    {
      id: 1,
      driverId : 2,
      itineraryName: "Sahloul",
      busId: 1,
      zipCodes : "2020",
      driver: {
        firstName: "Foulen",
        lastName: "Ben foulen",
      },
    },
    {
      id: 2,
      driverId : 2,
      itineraryName: "Bab bhar",
      zipCodes : "2051",
      busId: 2,
      driver: {
        firstName: "Foulen",
        lastName: "Ben foulen",
      },
    },
    {
      id: 3,
      itineraryName: "Bab bhar",
      zipCodes : "2053",
      driverId : 2,
      busId: 3,
      driver: {
        firstName: "Foulen",
        lastName: "Ben foulen",
      },
    },
  ],
  currentItineraire: null,
};

const Itineraires = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ITINERAIRE: {
      return {
        ...state,
        itineraires: action.payload,
      };
    }

    case ADD_ITINERAIRE: {
      return {
        ...state,
        itineraires: [action.payload, ...state.itineraires],
      };
    }
    case EDIT_ITINERAIRE: {
      return {
        ...state,
        itineraires: state.itineraires.map((ITINERAIRE) =>
          ITINERAIRE.id === action.payload.id ? action.payload : ITINERAIRE
        ),
      };
    }

    case DELETE_ITINERAIRE: {
      return {
        ...state,
        itineraires: state.itineraires.filter(
          (ITINERAIRE) => ITINERAIRE.id !== action.payload
        ),
      };
    }
    case SET_ITINERAIRE_DETAILS: {
      return {
        ...state,
        currentItineraire: action.payload,
      };
    }

    default:
      return state;
  }
};
export default Itineraires;
