import { combineReducers } from "redux";
import Common from "./Common";
import Auth from "./Auth";
import Users from "./Users";
import Establishments from "./Establishments";
import Licenses from "./License";
import Countries from "./Setting";
import Level from "./Level";
import SubjectModule from "./SubjectModule";
import Subject from "./Subject";
import Classroom from "./Classroom";
import Group from "./GroupClass";
import Materials from "./Materials";
import Session from "./Session";
import AdministrativeDocuments from "./AdministrativeDocuments";
import EducationalSupport from "./EducationalSupport";
import Holiday from "./Holiday";
import Vacation from "./Vacation";
import NotificationReducer from "./Notification";
import Operator from "./Operator";
import Room from "./Room";
import Professor from "./Professor";
import Multimedia from "./Multimedia";
import Canteen from "./Canteen";
import SuggestionChef from "./SuggestionChef";
import AdminLocal from "./AdminLocal";
import Student from "./Student";
import Event from "./Event";
import Dashboard from "./Dashboard";
import Complaint from "./Complaint";
import Vaccinations from "./Vaccination";
import Clubs from "./Clubs";
import News from "./News";
import Payslips from "./Payslips";
import DiscussionGroups from "./DiscussionGroups";
import MailApp from "./MailApp";
import ServicesManagements from "./ServiceManagements";
import AssignmentServices from "./AssignmentServices";
import FinancialMonitoring from "./FinancialMonitoring";
import SchoolGrades from "./SchoolGrades";
import MedicalHistory from "./MedicalHistory";
import Attendance from "./Assiduity";
import Sanction from "./Sanction";
import Section from "./Section";
import Harassement from "./Harassement";
import CollaboratorMonitoring from "./CollaboratorMonitoring";
import SchoolSupplies from "./SchoolSupplies";
import OperatorPermissions from "./OperatorPermissions";
import SchoolReportsCards from "./SchoolReportsCards";
import SocketIOReducer from "./SocketIO";
import CountData from "./CountData";
import LangReducer from "./LangReducer";
import Parents from "./Parents";
import EduDrive from "./EduDrive";
import Bus from "./Bus";
import Itineraires from "./Itineraire";

import Reports from "./ReportsReducer";
const reducers = () =>
  combineReducers({
    common: Common,
    auth: Auth,
    usersReducer: Users,
    EduDrive: EduDrive,
    EstablishmentsReducer: Establishments,
    LicensesReducer: Licenses,
    SettingReducer: Countries,
    LevelsReducer: Level,
    SubjectModuleReducer: SubjectModule,
    SubjectReducer: Subject,
    ClassroomsReducer: Classroom,
    GroupsReducer: Group,
    MaterialsReducer: Materials,
    SessionReducer: Session,
    AdministrativeDocumentsReducer: AdministrativeDocuments,
    EducationalSupportReducer: EducationalSupport,
    HolidayReducer: Holiday,
    VacationsReducer: Vacation,
    OperatorReducer: Operator,
    RoomsReducer: Room,
    ProfessorReducer: Professor,
    MultimediaReducer: Multimedia,
    CanteenReducer: Canteen,
    SuggestionChefReducer: SuggestionChef,
    AdminLocalReducer: AdminLocal,
    StudentReducer: Student,
    EventReducer: Event,
    DashboardReducer: Dashboard,
    ComplaintReducer: Complaint,
    VaccinationsReducer: Vaccinations,
    ClubsReducer: Clubs,
    NewsReducer: News,
    PayslipsReducer: Payslips,
    DiscussionGroupsReducer: DiscussionGroups,
    mailApp: MailApp,
    ServicesManagementsReducer: ServicesManagements,
    AssignmentServicesReducer: AssignmentServices,
    FinancialMonitoring: FinancialMonitoring,
    SchoolGradesReducer: SchoolGrades,
    MedicalHistoryReducer: MedicalHistory,
    AttendanceReducer: Attendance,
    SanctionReducer: Sanction,
    SectionsReducer: Section,
    HarassementReducer: Harassement,
    CollaboratorMonitoringReducer: CollaboratorMonitoring,
    SchoolSuppliesReducer: SchoolSupplies,
    OperatorPermissionsReducer: OperatorPermissions,
    SchoolReportsCardsReducer: SchoolReportsCards,
    CountDataReducer: CountData,
    SocketIOReducer,
    Notification: NotificationReducer,
    LangReducer,
    ParentsReducer: Parents,
    ReportsReducer: Reports,
    BusReducer: Bus,
    ItineraireReducer: Itineraires,
  });
export default reducers;
