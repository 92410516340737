import { useEffect, useState } from "react";
// import { parsePhoneNumberFromString } from "libphonenumber-js";
import _ from "lodash";
import moment from "moment";
import { isMonth, giveDateFormat } from "./dateHelper";
import React from "react";
import IntlMessages from "./IntlMessages";
import validator from "validator";
import * as XLSX from "xlsx";

export const isValidUrl = (webSite) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(webSite);
};
export const capitalizeFLetter = (string) => {
  return string[0]?.toUpperCase() + string.slice(1);
};

export const isValidEmail = (value) => {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
};

export const isPhonenumber = (str) => {
  if (str.length > 1) return /^[2-9]\d{7}$/.test(str);
  else return true;
};

export const isValidphoneNumber = (number) => {
  return validator.isMobilePhone(number);
  // if (number !== undefined && number !== "") {
  //   const tel = parsePhoneNumberFromString(number?.toString());
  //   let res = false;
  //   if (number === "+") {
  //     res = true;
  //   }
  //   if (tel || tel === undefined) {
  //     res = tel?.isValid();
  //   }

  //   return res;
  // }
};

export const isCIN = (cin) => {
  if (cin !== null && cin.length > 0) return /^[0-9]\d{7,11}$/.test(cin);
  else return true;
};

export const linkify = (inputText) => {
  let replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gim;
  replacedText = inputText.replace(
    replacePattern1,
    '<a href="$1" target="_blank">$1</a>'
  );

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z0-9\\-]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a href="mailto:$1">$1</a>'
  );

  return replacedText;
};

export const geValidUrl = (url, ubSecureUrl = false) => {
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    if (ubSecureUrl) {
      return "http://" + url;
    }
    return "https://" + url;
  }

  return url;
};
export const isValidPassWord = (value) => {
  if (value != null) {
    let validPassword =
      value.match(/[0-9]/g) &&
      value.match(/[A-Z]/g) &&
      value.match(/[a-z]/g) &&
      value.match(/[^a-zA-Z\d]/g) &&
      value.length >= 12;
    return validPassword;
  } else {
    return false;
  }
};
export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState("");

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value, delay]
  );

  return debouncedValue;
};

export function toUpperCaseFirst(string) {
  if (!_.isEmpty(string)) {
    string.toLowerCase();
    return string?.charAt(0).toUpperCase() + string.substring(1);
  }
}

export function toUpperCase(string) {
  if (!_.isEmpty(string)) {
    return string.toUpperCase();
  }
}

export function toLowerCaseFirst(string) {
  if (!_.isEmpty(string)) return string.toLowerCase();
}

export function equalName(alimentA, alimentB) {
  return alimentA === alimentB;
}

export function matchAfterUrl(string) {
  const regex = /aliments\/(.*)/;
  return string.match(regex)[1];
}

export function convTime(time) {
  return moment(time).format("HH[h]mm");
}
export function convFormatDate(date) {
  return moment(date).format("DD/MM/YYYY");
}
// Differance entre deux temps
export function deltaTime(time1, time2) {
  let now = moment(time1).format("DD/MM/YYYY HH:mm:ss");
  let then = moment(time2).format("DD/MM/YYYY HH:mm:ss");
  let ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(
    moment(then, "DD/MM/YYYY HH:mm:ss")
  );
  let d = moment.duration(ms);
  let s = Math.floor(d.asHours()) + moment.utc(ms).format("[h]mm");
  return s;
}

export const isAfterMinBirthDate = (birthDate) => {
  return moment(birthDate).isAfter(moment().format("2016-12-31"));
};
export function UpperWords(sentence) {
  let str;
  let array = [];

  str = sentence.trim();
  str = str.toLowerCase().split(",");
  str.forEach((word) => {
    word = word.trim();
    let firsLetter = word[0]?.charAt(0).toUpperCase();
    let replacement = word?.replace(word?.charAt(0), firsLetter);
    array.push(replacement);
  });
  return array;
}
export function DynamicCalandarMenu(currentDay, data) {
  var Monday = new Date(
    currentDay.setDate(currentDay.getDate() - currentDay.getDay() + 1)
  );
  var Tuesday = new Date(
    currentDay.setDate(currentDay.getDate() - currentDay.getDay() + 2)
  );
  var Wednesday = new Date(
    currentDay.setDate(currentDay.getDate() - currentDay.getDay() + 3)
  );
  var Thursday = new Date(
    currentDay.setDate(currentDay.getDate() - currentDay.getDay() + 4)
  );
  var Friday = new Date(
    currentDay.setDate(currentDay.getDate() - currentDay.getDay() + 5)
  );
  var Saturday = new Date(
    currentDay.setDate(currentDay.getDate() - currentDay.getDay() + 6)
  );
  //Create a dynamic Menu calandar
  var weekList = [
    {
      day: <IntlMessages id="monday" />,
      date:
        Monday.getDate() + "-" + isMonth(Monday) + "-" + Monday.getFullYear(),
      formDate: Monday,
      idMenu: "",
      id1: "",
      enter: "",
      ingredientEnter: "",
      urlEnter: "",
      id2: "",
      suite: "",
      ingredientSuite: "",
      urlSuite: "",
      id3: "",
      dessert: "",
      ingredientDessert: "",
      urlDessert: "",
    },
    {
      day: <IntlMessages id="tuesday" />,
      date:
        Tuesday.getDate() +
        "-" +
        isMonth(Tuesday) +
        "-" +
        Tuesday.getFullYear(),
      formDate: Tuesday,
      idMenu: "",
      id1: "",
      enter: "",
      ingredientEnter: "",
      urlEnter: "",
      id2: "",
      suite: "",
      ingredientSuite: "",
      urlSuite: "",
      id3: "",
      dessert: "",
      ingredientDessert: "",
      urlDessert: "",
    },
    {
      day: <IntlMessages id="wednesday" />,
      date:
        Wednesday.getDate() +
        "-" +
        isMonth(Wednesday) +
        "-" +
        Wednesday.getFullYear(),
      formDate: Wednesday,
      idMenu: "",
      id1: "",
      enter: "",
      ingredientEnter: "",
      urlEnter: "",
      id2: "",
      suite: "",
      ingredientSuite: "",
      urlSuite: "",
      id3: "",
      dessert: "",
      ingredientDessert: "",
      urlDessert: "",
    },
    {
      day: <IntlMessages id="thursday" />,
      date:
        Thursday.getDate() +
        "-" +
        isMonth(Thursday) +
        "-" +
        Thursday.getFullYear(),
      formDate: Thursday,
      idMenu: "",
      id1: "",
      enter: "",
      ingredientEnter: "",
      urlEnter: "",
      id2: "",
      suite: "",
      ingredientSuite: "",
      urlSuite: "",
      id3: "",
      dessert: "",
      ingredientDessert: "",
      urlDessert: "",
    },
    {
      day: <IntlMessages id="friday" />,
      date:
        Friday.getDate() + "-" + isMonth(Friday) + "-" + Friday.getFullYear(),
      formDate: Friday,
      idMenu: "",
      id1: "",
      enter: "",
      ingredientEnter: "",
      urlEnter: "",
      id2: "",
      suite: "",
      ingredientSuite: "",
      urlSuite: "",
      id3: "",
      dessert: "",
      ingredientDessert: "",
      urlDessert: "",
    },
    {
      day: <IntlMessages id="saturday" />,
      date:
        Saturday.getDate() +
        "-" +
        isMonth(Saturday) +
        "-" +
        Saturday.getFullYear(),
      formDate: Saturday,
      idMenu: "",
      id1: "",
      enter: "",
      ingredientEnter: "",
      urlEnter: "",
      id2: "",
      suite: "",
      ingredientSuite: "",
      urlSuite: "",
      id3: "",
      dessert: "",
      ingredientDessert: "",
      urlDessert: "",
    },
  ];

  data.map((row) => {
    weekList.map((now) => {
      const date = giveDateFormat(row.date.toString().slice(0, 10));
      if (now.date === date) {
        now.idMenu = row.id;
        now.id1 = row.starterDish.id;
        now.enter = row.starterDish.name;
        now.ingredientEnter = row.starterDish.ingredients;
        now.urlEnter = row.starterDish.photoUrl;
        now.id2 = row.flatContinued.id;
        now.suite = row.flatContinued.name;
        now.ingredientSuite = row.flatContinued.ingredients;
        now.urlSuite = row.flatContinued.photoUrl;
        now.id3 = row.desert.id;
        now.dessert = row.desert.name;
        now.ingredientDessert = row.desert.ingredients;
        now.urlDessert = row.desert.photoUrl;
      }
      return true;
    });
    return true;
  });
  return weekList;
}

// get day name from date
export function dayNameDate(date) {
  const day = moment(date).format("dddd");
  switch (day) {
    case "lundi":
      return "#F9EAFF";
    case "mardi":
      return "#D4F2FF";
    case "mercredi":
      return "#C8C3F4";
    case "jeudi":
      return "#FDEED4";
    case "vendredi":
      return "#FCDCDF";
    case "samedi":
      return "#E5FFBA";
    default:
      return null;
  }
}

export const isOrderByDesc = (data, key) => {
  if (key === "creationDate")
    return _.orderBy(data, [(item) => item.key], ["desc"]);
  else return _.orderBy(data, [key], ["desc"]);
};
export const isOrderByAscLevel = (data) => {
  return _.orderBy(data, [(user) => user?.levelName?.toLowerCase()], ["asc"]);
};
export const isOrderByAscClass = (data) => {
  return _.orderBy(data, [(user) => user?.className?.toLowerCase()], ["asc"]);
};
export const isOrderByDscStudentList = (data) => {
  return _.orderBy(data, [(user) => user?.lastName?.toLowerCase()], ["asc"]);
};

export const isNegative = (value) => {
  if (Math.sign(value) === -1) {
    return true;
  }

  return false;
};

export const filterLicense = (list, searchTerm, filterOptions, key) => {
  const filterLength = filterOptions.length;
  let filteredList = list;
  if (searchTerm || filterLength > 0) {
    if (searchTerm && filterLength > 0) {
      filteredList = list.filter(
        (item) =>
          item.subEstablishment[key]
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) &&
          filterOptions.includes(item.licenseStatus)
      );
    } else if (searchTerm) {
      filteredList = list.filter((item) =>
        item.subEstablishment[key]
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    } else {
      filteredList = list.filter((item) =>
        filterOptions.includes(item.licenseStatus)
      );
    }
  }

  return filteredList;
};

export const filterList = (users, searchTerm, filterOptions) => {
  const filterLength = filterOptions.length;
  let filteredUsers = users;

  if (searchTerm || filterLength > 0) {
    if (searchTerm && filterLength > 0) {
      filteredUsers = users.filter((item) =>
        item.user
          ? (item.user?.lastName
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
              item.user?.firstName
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) &&
            filterOptions.includes(item.user?.roleId)
          : (item.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
              item.firstName
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) &&
            filterOptions.includes(item?.roleId)
      );
    } else if (searchTerm) {
      filteredUsers = users.filter((item) =>
        item.user
          ? item.user?.lastName
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item.user?.firstName
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase())
          : item.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.firstName?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      filteredUsers = users.filter((item) =>
        item.user
          ? filterOptions.includes(item.user?.roleId)
          : filterOptions.includes(item?.roleId)
      );
    }
  }

  return filteredUsers;
};

export const search = (
  list,
  searchTerm,
  key,
  filterOptions = [],
  key2 = null
) => {
  var filteredList = list?.filter((item) =>
    item ? item[key]?.toLowerCase().includes(searchTerm.toLowerCase()) : null
  );
  if (filterOptions.length)
    filteredList = filteredList.filter((item) =>
      filterOptions.includes(item.establishmentType)
    );
  return filteredList;
};

export const searchUserWithFistNameLastName = (
  list,
  searchTerm,
  key1,
  key2
) => {
  let filteredList = list.filter(
    (item) =>
      item[key1]?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      item[key2]?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      item[key1]
        .concat(" ", item[key2])
        ?.toLowerCase()
        ?.includes(searchTerm?.toLowerCase()) ||
      item[key2]
        .concat(" ", item[key1])
        ?.toLowerCase()
        ?.includes(searchTerm?.toLowerCase())
  );
  return filteredList;
};

export const searchWithKeys = (list, searchTerm, key1, key2, key3) => {
  let filteredList =
    list.length !== 0
      ? list.filter(
          (item) =>
            item[key1][key2]
              ?.toLowerCase()
              .includes(searchTerm?.toLowerCase()) ||
            item[key1][key3]?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : [];

  return filteredList;
};

export const searchFunctionByClass = (list, searchTerm) => {
  let filteredList = [];
  let firstFilter = list.filter((item) => {
    let secondFilter = item.classrooms.filter((elm) => {
      if (elm.className.toLowerCase().includes(searchTerm.toLowerCase())) {
        filteredList.push(item);
      }
      return secondFilter;
    });

    return firstFilter;
  });

  return _.uniqBy(filteredList, "id");
};

export const downLoadCsv = (urlFile) => {
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", urlFile);
  a.setAttribute("download", `file.csv`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const searchKeyAndOption = (
  users,
  searchTerm,
  filterOptions,
  key1,
  key2
) => {};

export const filterRoomsList = (rooms, searchTerm, filterOptions) => {
  const filterLength = filterOptions.length;
  let filteredRooms = rooms;

  if (searchTerm || filterLength > 0) {
    if (searchTerm && filterLength > 0) {
      filteredRooms = rooms.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
          filterOptions.includes(item?.exploitation)
      );
    } else if (searchTerm) {
      filteredRooms = rooms.filter((item) =>
        item.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      filteredRooms = rooms.filter((item) =>
        filterOptions.includes(item?.exploitation)
      );
    }
  }

  return filteredRooms;
};
export const filterBusList = (bus, searchTerm, filterOptions) => {
  const filterLength = filterOptions.length;
  let filteredBus = bus;

  if (searchTerm || filterLength > 0) {
    if (searchTerm && filterLength > 0) {
      filteredBus = bus.filter((item) =>
      item.matricule.toLowerCase().includes(searchTerm.toLowerCase()) &&
      filterOptions.includes(item?.state)
  );
    } else if (searchTerm) {
      filteredBus = bus.filter((item) =>
      item.matricule.toLowerCase().includes(searchTerm.toLowerCase())
        );     
    } else {
      filteredBus = bus.filter((item) =>
      item.matricule.toLowerCase().includes(searchTerm.toLowerCase()) &&
      filterOptions.includes(item?.state)
      );
    }
  }

  return filteredBus;
};

export function filterSubjects(noAffectedClass) {
  var listSubjectClass = [];
  var commonclassess = [];
  var listSubject = [];
  noAffectedClass.forEach((row) => {
    let selectedSubject = _.uniqBy(
      _.flatten(_.map(row.classroomParams, "subject"), "subject.id")
    );
    listSubjectClass.push(selectedSubject);
  });
  commonclassess = _.flatten(listSubjectClass);
  noAffectedClass.forEach((row) => {
    var listSubjectt = [];
    row.classroomParams.forEach((subj) => {
      listSubjectt.push(subj.subject);
    });
    listSubject.push(listSubjectt);
  });
  listSubject.forEach((row) => {
    commonclassess = _.intersectionBy(commonclassess, row, "id");
    return commonclassess;
  });
  return commonclassess;
}
export function titleCase(str) {
  // Split the string into an array of words
  let words = str.split(" ");
  // Use map() to iterate over each word
  words = words.map(function(word) {
    // Use slice() to get the first character and uppercase it
    let firstChar = word?.slice(0, 1)?.toUpperCase();

    // Use slice() again to get the rest of the word and lowercase it
    let restOfWord = word?.slice(1)?.toLowerCase();

    // Concatenate the uppercase first character with the rest of the word
    return firstChar + restOfWord;
  });

  // Join the array of words back into a string
  return words.join(" ");
}
export const getComplaintPattern = (pattern) => {
  switch (pattern) {
    case "Bus Driver":
      return "Conducteur de bus";
    case "Inappropriate Behavior":
      return "Comportement inapproprié";
    case "Bullying":
      return "Intimidation";
    case "Verbal Harassment":
      return "Harcèlement verbal";
    case "Exam Score":
      return "Note d'examen";
    case "Homework Quantity":
      return "Quantité de devoirs";
    case "Inappropriate work space":
      return "Espace de travail inapproprié";
    case "Other":
      return "Autre";
    default:
      return "";
  }
};
export const getComplaintPatternList = (pattern) => {
  switch (pattern) {
    case "Bus Driver":
      return <IntlMessages id="app.module.complaint.type.busDriver" />;
    case "Inappropriate Behavior":
      return (
        <IntlMessages id="app.module.complaint.type.inappropriateBehavior" />
      );
    case "Bullying":
      return <IntlMessages id="app.module.complaint.type.bullying" />;
    case "Verbal Harassment":
      return <IntlMessages id="app.module.complaint.type.verbalHarassment" />;
    case "Exam Score":
      return <IntlMessages id="app.module.complaint.type.examScore" />;
    case "Homework Quantity":
      return <IntlMessages id="app.module.complaint.type.homeworkQuantity" />;
    case "Inappropriate work space":
      return (
        <IntlMessages id="app.module.complaint.type.inappropriateWorkSpace" />
      );
    case "Other":
      return <IntlMessages id="app.module.complaint.type.other" />;
    default:
      return "";
  }
};

export const handleAbsenceExport = (Attendance, classroom, classRoomId) => {
  let exportData = [];
  Attendance.forEach((element) => {
    element.callRegister?.details.map((row) =>
      exportData?.push({
        Classe: classroom.originName,
        Élève:
          row?.student?.user?.firstName + " " + row?.student?.user?.lastName,
        Matière: element?.subject?.subjectName,
        Professeur:
          element?.professor?.user?.firstName +
          " " +
          element?.professor?.user?.lastName,
        Statut: row?.type,
        "Date séance": moment(element?.startDate).format(`LL`),
        "Heure de début": moment(element?.startDate).format(`LT`),
        "Heure de fin": moment(element?.endDate).format(`LT`),
      })
    );
    return exportData;
  });
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(exportData);
  XLSX.utils.book_append_sheet(wb, ws, "Notes");
  XLSX.writeFile(wb, `Suivi présence ${classRoomId}.xlsx`);
};

export const handleSectionExport = (sanction) => {
  let exportData = [];
  sanction.forEach((element) => {
    return exportData?.push({
      Classe: element.classroomName,
      Élève: element.studentName,
      "Type de sanction": element.type,
      "Date de début": moment(element.startDate).format(`LL`),
      "Date de  fin": element.endDate
        ? moment(element.endDate).format(`LL`)
        : " - ",
      Matière: element?.subjectName,
      Professeur: element?.professorName,
    });
  });
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(exportData);
  XLSX.utils.book_append_sheet(wb, ws, "Notes");
  XLSX.writeFile(wb, `Sanction- ${sanction.classroomName ?? null}.xlsx`);
};
export function filterSameFolderNames(folders, baseName, isFile) {
  // Filter folders with the same base name
  const filteredFolders = folders.filter((folder) => {
    const folderName = isFile
      ? folder.name
          .split(".")
          .slice(0, -1)
          .join(".")
      : folder.name;
    const baseNameRegex = new RegExp(`^${baseName}( \\([0-9]+\\))?$`);

    return folderName.match(baseNameRegex);
  });

  return filteredFolders.length;
}
