import React, { useEffect, useState } from "react";
import Hidden from "@material-ui/core/Hidden";
import { Box, FormControl, Grid, InputBase, MenuItem } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import CmtImage from "../../../../@coremat/CmtImage";
import { useSelector } from "react-redux";
import useStyles from "../../../../routes/CommonComponents/Style/MenuStyle";
import { roleIdSAL, roleIdADL, roleIdOperator } from "../../../../Config";

import _ from "lodash";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { useDispatch } from "react-redux";
import { AuhMethods } from "../../../../services/auth";
import { setSelectedSchoolYear } from "redux/actions/Auth";

const Logo = ({ color, ...props }) => {
  const { userProfile } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const logoUrl =
    color === "white" ? "/images/Groupe 1163.svg" : "/images/logo.png";
  const dispatch = useDispatch();
  const [schoolYear, setSchoolYear] = useState(userProfile?.schoolYearId);
  const [allSchoolYearsLicence, setAllSchoolYearsLicence] = useState([]);


  useEffect(() => {
    let licences = userProfile?.allLicences
      ?.filter((e) => e?.licenseStatus !== "Bloquée")
      .map((e) => {
        return { licenseStatus: e.licenseStatus, schoolyear: e?.schoolyear };
      });

    setAllSchoolYearsLicence(licences);
  }, [userProfile]);

  const handleClickSchoolYearItem = (event) => {
    dispatch(
      AuhMethods["jwtAuth"].getScopeUser(
        userProfile?.subEstablishmentId,
        undefined,
        undefined,
        event?.target?.value
      )
    );
  };

  useEffect(() => {
    if (
      userProfile?.schoolYearId !== undefined &&
      userProfile?.schoolYearId !== null
    ) {
      // ---- set school year initial status
      setSchoolYear(userProfile?.schoolYearId);

      // ---- set choosen licences status
      let activeSchoolYear = allSchoolYearsLicence?.filter(
        (e) => e?.schoolyear?.id === userProfile?.schoolYearId
      );

      if (activeSchoolYear !== undefined)
        dispatch(setSelectedSchoolYear(activeSchoolYear[0]?.licenseStatus));
    }
  }, [allSchoolYearsLicence, userProfile.schoolYearId, dispatch]);

  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <Grid container>
          <NavLink
            to={
              userProfile?.roleId === roleIdSAL
                ? "/app/superadmin/dashboard"
                : userProfile?.roleId === roleIdADL
                ? "/app/admin/dashboard"
                : userProfile?.roleId === roleIdOperator
                ? "/app/operator/dashboard"
                : "/app/sag/establishment-management"
            }
          >
            <CmtImage id="logoEducap" src={logoUrl} alt="logo" />
          </NavLink>

          <Grid
            item
            xs={12}
            className={classes.navEstablishmenetTitle}
            style={{
              color: color === "white" ? "#FFFFFF" : "#4D4B4B",
              display: "flex",
            }}
          >
            {!_.isEmpty(userProfile.subEstablishmentName) &&
              userProfile?.subEstablishmentName}
          </Grid>
          {/* <Grid
              xs={4}
              item
              className={classes.currentLicence}
              style={{ color: color === "white" ? "#FFFFFF" : "#4D4B4B" }}
            >
              {!_.isEmpty(userProfile?.schoolYearName) &&
                userProfile?.schoolYearName}
            </Grid> */}

          {userProfile?.roleId !== 1 && (
            <FormControl
              fullWidth
              // variant="outlined"
              className={classes.dropDownBg}
            >
              <AppSelectBox
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #b8b1f3",
                  borderRadius: "4px",
                  margin: "10px 7px",
                  color: "#b8b1f3",
                  padding: "3px 0 0px 8px",
                }}
                id="establishmentId"
                name="establishmentId"
                data={allSchoolYearsLicence}
                value={schoolYear !== undefined && schoolYear}
                valueKey="id"
                // variant="outlined"
                labelKey="year"
                displayEmpty={false}
                onChange={handleClickSchoolYearItem}
                input={<InputBase style={{ color: "#b8b1f3" }} />}
                renderRow={(item, index) => (
                  <MenuItem key={index} value={item?.schoolyear?.id}>
                    {item?.schoolyear?.year}
                  </MenuItem>
                )}
              />
            </FormControl>
          )}
        </Grid>
      </Hidden>
      <Hidden smUp>
        <NavLink to="/">
          <CmtImage src={logoUrl} alt="logo" />
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default Logo;
