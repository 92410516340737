import IntlMessages from "../../../utils/IntlMessages";
import React from "react";
import { ArrowForward } from "@material-ui/icons";
import schoolParametersIcon from "../../../../assets/images/Icone paramètres scolaires.svg";
import administrativeDocumentsIcon from "../../../../assets/images/Icone documents administratifs.svg";
import establishmentSheetIcon from "../../../../assets/images/Icone fiche etablissement.svg";
import classroomIcon from "../../../../assets/images/Icone gestion des salles.svg";
import profileIcon from "../../../../assets/images/Icone profil, gestion des comptes.svg";
import establishmentIcon from "../../../../assets/images/estab.svg";
import lisenceIcon from "../../../../assets/images/lisence.svg";
import iconHome from "../../../../assets/images/icon-home.svg";
import iconAccountManagement from "../../../../assets/images/icon-account-management.svg";
// import iconConnexionETQ from "../../../../assets/images/icon-connexion-ETQ.svg";
import iconAttendanceTacking from "../../../../assets/images/icon-attendance-tacking.svg";
import iconSanctionsHistory from "../../../../assets/images/icon-sanctions-history.svg";
import iconSchoolSupplies from "../../../../assets/images/icon-school-supplies.svg";
import iconGradeTracking from "../../../../assets/images/icon-grade-tracking.svg";
import file from "../../../../assets/images/file.svg";
import buelltins from "../../../../assets/images/buelltins.svg";
import iconMultimediaSupport from "../../../../assets/images/icon-multimedia-support.svg";
import iconEducationalSupport from "../../../../assets/images/icon-educational-support.svg";
import iconMenuWeek from "../../../../assets/images/icon-menu-of-the-week.svg";
import iconChefSuggestion from "../../../../assets/images/icon-chef-suggestion.svg";
import iconCanteenManagement from "../../../../assets/images/icon-canteen-management.svg";
import iconPayslip from "../../../../assets/images/icon-payslip.svg";
import iconEmployeesTracking from "../../../../assets/images/icon-employees-tracking.svg";
import iconSchoolSetting from "../../../../assets/images/icon-school-setting.svg";
import iconListDeadlines from "../../../../assets/images/icon-list-of-deadlines.svg";
import iconPaymentHistory from "../../../../assets/images/icon-payment-history.svg";
import iconVaccinationCard from "../../../../assets/images/icon-vaccination-card.svg";
import iconMedicalMonitoring from "../../../../assets/images/icon-medical-monitoring.svg";
import iconReclamation from "../../../../assets/images/icon-reclamation.svg";
import iconMessenger from "../../../../assets/images/icon-Messenger.svg";
import iconClubManagement from "../../../../assets/images/icon-club-management.svg";
import iconClubJournal from "../../../../assets/images/Icon-club-jornal.svg";
import iconNews from "../../../../assets/images/icon-News.svg";
import iconHarassmentAlert from "../../../../assets/images/Groupe 1161.svg";
import iconDrive from "../../../../assets/images/drive.png";
import classSettings from "../../../../assets/images/class-settings.png";
import bus from "../../../../assets/images/bus.png";
import itineraire  from "../../../../assets/images/itineraire.png";

import dashboardIcon from "../../../../assets/images/dashboardIcon.png";

////////////superAdminGlobaleMenus/////////
const superAdminGlobaleMenus = [
  {
    name: <IntlMessages id={"sidebar.sag.establishment.management"} />,
    icon: <img src={establishmentIcon} alt="establishmentIcon" />,
    type: "item",
    link: "/app/sag/establishment-management",
    pathName: "establishment-management",
  },
  {
    name: <IntlMessages id={"sidebar.sag.license.management"} />,
    icon: <img src={lisenceIcon} alt="profileIcon" />,
    type: "item",
    link: "/app/sag/license-management",
    pathName: "license-management",
  },
  {
    name: <IntlMessages id={"sidebar.sag.account.management"} />,
    icon: <img src={profileIcon} alt="profileIcon" />,
    type: "item",
    link: "/app/sag/account-management",
    pathName: "account-management",
  },
  // {
  //   name: <IntlMessages id={"sidebar.sag.connection.as"} />,
  //   icon: <img src={iconConnexionETQ} alt="iconConnexionETQ" />,
  //   type: "item",
  //   link: "/app/sag/connection-as",
  //   pathName: "connection-as",
  // },
];

////////////superAdminLocalMenus/////////
const childrenWithSection = {
  name: <IntlMessages id={"sidebar.school.parameters"} />,
  icon: <img src={schoolParametersIcon} alt="schoolParametersIcon" />,
  type: "collapse",
  pathName: "/school-settings",
  id: "schoolSettings",
  children: [
    {
      type: "section",
      children: [
        {
          name: (
            <IntlMessages id={"sidebar.components.school.parameters.levels"} />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/levels",
        },
        {
          name: (
            <IntlMessages
              id={"sidebar.components.school.parameters.sections"}
            />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/sections",
        },
        {
          name: (
            <IntlMessages id={"dashboard.classes"} />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/classes",
        },
        {
          name: (
            <IntlMessages id={"sidebar.components.school.parameters.groups"} />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/groups",
        },
        {
          name: (
            <IntlMessages
              id={"sidebar.components.school.parameters.subjectModule"}
            />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/subject-module",
        },
        {
          name: (
            <IntlMessages
              id={"sidebar.components.school.parameters.materials"}
            />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/subjects",
        },
        {
          name: (
            <IntlMessages
              id={"sidebar.components.school.parameters.schoolSessions"}
            />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/school-sessions",
        },
        {
          name: (
            <IntlMessages
              id={"sidebar.components.school.parameters.holidays"}
            />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/holidays",
        },
        {
          name: (
            <IntlMessages
              id={"sidebar.components.school.parameters.schoolVacation"}
            />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/school-Vaccination",
        },
      ],
      roleName: "sal",
    },
  ],
};



const schoolParametersSal = {
  name: <IntlMessages id={"sidebar.school.parameters"} />,
  icon: <img src={schoolParametersIcon} alt="schoolParametersIcon" />,
  type: "collapse",
  pathName: "/school-settings",
  id: "schoolSettings",
  children: [
    {
      type: "section",
      children: [
        {
          name: (
            <IntlMessages id={"sidebar.components.school.parameters.levels"} />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/levels",
        },
        {
          name: (
            <IntlMessages id={"dashboard.classes"} />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/classes",
        },
        {
          name: (
            <IntlMessages id={"sidebar.components.school.parameters.groups"} />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/groups",
        },
        {
          name: (
            <IntlMessages
              id={"sidebar.components.school.parameters.subjectModule"}
            />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/subject-module",
        },
        {
          name: (
            <IntlMessages
              id={"sidebar.components.school.parameters.materials"}
            />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/subjects",
        },
        {
          name: (
            <IntlMessages
              id={"sidebar.components.school.parameters.schoolSessions"}
            />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/school-sessions",
        },
        {
          name: (
            <IntlMessages
              id={"sidebar.components.school.parameters.holidays"}
            />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/holidays",
        },
        {
          name: (
            <IntlMessages
              id={"sidebar.components.school.parameters.schoolVacation"}
            />
          ),
          type: "item",
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          link: "/school-parameters/school-Vaccination",
        },
      ],
      roleName: "sal",
    },
  ],
};

const accountManagementSal = {
  name: <IntlMessages id={"sidebar.account.management"} />,
  icon: <img src={iconAccountManagement} alt="iconAccountManagement" />,
  type: "collapse",
  children: [
    {
      type: "section",
      children: [
        {
          name: (
            <IntlMessages
              id={"sidebar.components.account.management.createUser"}
            />
          ),
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          type: "item",
          link: "/account-management/create-user",
        },
        {
          name: (
            <IntlMessages
              id={"sidebar.components.account.management.permission.manager"}
            />
          ),
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          type: "item",
          link: "/account-management/permission-management",
        },
        {
          name: (
            <IntlMessages
              id={"sidebar.components.account.management.importExport"}
            />
          ),
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          type: "item",
          link: "/account-management/import-export",
        },
      ],
      roleName: "sal",
    },
  ],
  pathName: "/users-management",
  id: "accountManagement",
};
const accountManagementSalExpired = {
  name: <IntlMessages id={"sidebar.account.management"} />,
  icon: <img src={iconAccountManagement} alt="iconAccountManagement" />,
  type: "collapse",
  children: [
    {
      type: "section",
      children: [
        {
          name: (
            <IntlMessages
              id={"sidebar.components.account.management.createUser"}
            />
          ),
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          type: "item",
          link: "/account-management/create-user",
        },

        {
          name: (
            <IntlMessages
              id={"sidebar.components.account.management.importExport"}
            />
          ),
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          type: "item",
          link: "/account-management/import-export",
        },
      ],
      roleName: "sal",
    },
  ],
  pathName: "/users-management",
};


const parkManagementSal = {
  name: <IntlMessages id={"sidebar.components.park"} />,
  icon: <img src={bus} alt="iconParkManagement" />,
  type: "collapse",
  children: [
    {
      type: "section",
      children: [
        {
          name: (
            <IntlMessages
              id={"sidebar.components.park.createBus"}
            />
          ),
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          type: "item",
          link: "/park-managment/create-bus",
        },

      
      ],
      roleName: "sal",
    },
    {
      type: "section",
      children: [
        {
          name: (
            <IntlMessages
              id={"sidebar.components.park.createItineraire"}
            />
          ),
          icon: <ArrowForward style={{ color: "#5B13B4" }} />,
          type: "item",
          link: "/park-managment/create-itineraire",
        },

      
      ],
      roleName: "sal",
    },
  ],
  pathName: "/park-managment",
  id:"parkmanagment"
};

// const itineraireManagement = {
//   name: <IntlMessages id={"sidebar.components.chauffeur"} />,
//   icon: <img src={iconAccountManagement} alt="iconAccountManagement" />,
//   type: "collapse",
//   children: [
//     {
//       type: "section",
//       children: [
//         {
//           name: (
//             <IntlMessages
//               id={"sidebar.components.chauffeur.planing"}
//             />
//           ),
//           icon: <ArrowForward style={{ color: "#5B13B4" }} />,
//           type: "item",
//           link: "/route-management/planing",
//         },

      
//       ],
//       roleName: "sal",
//     },
//     {
//       type: "section",
//       children: [
//         {
//           name: (
//             <IntlMessages
//               id={"sidebar.components.chauffeur.routes"}
//             />
//           ),
//           icon: <ArrowForward style={{ color: "#5B13B4" }} />,
//           type: "item",
//           link: "/route-management/routing",
//         },

      
//       ],
//       roleName: "sal",
//     },
//   ],
//   pathName: "/route-managment",
// };
// const itineraireManagement = {
//   name: <IntlMessages id={"sidebar.components.itineraire"} />,
//   icon: <img src={classroomIcon} alt="classroomIcon" />,
//   type: "item",
//   link: "/itineraire-management",
//   pathName: "/route-managment",
// };
const chauffeurRoutes = {
  name: <IntlMessages id={"sidebar.components.page.home"} />,
  icon: <img src={iconHome} alt="iconHome" />,
 type: "item",
  link: "/driver",
  pathName: "/driver-route",
};

const realTimeTracking = {
  name: <IntlMessages id={"sidebar.components.consult"} />,
  icon: <img src={itineraire} alt="itineraryIcon" />,
  type: "item",
  link: "/realtime-management",
  pathName: "/realtime-tracking",
  id:"realtimetracking"
};


const classroomManagement = {
  name: <IntlMessages id={"sidebar.classroom.management"} />,
  icon: <img src={classroomIcon} alt="classroomIcon" />,
  type: "item",
  link: "/classroom-management",
  pathName: "/room-management",
  id:"classroomManagement"
};

const administrativeDocuments = {
  name: <IntlMessages id={"sidebar.administrative.documents"} />,
  type: "item",
  icon: (
    <img src={administrativeDocumentsIcon} alt="administrativeDocumentsIcon" />
  ),
  link: "/administrative-documents",
  pathName: "/administrative-documents",
  id:"administrativeDocuments"
};

const establishmentSheet = {
  name: <IntlMessages id={"sidebar.establishment.sheet"} />,
  type: "item",
  icon: <img src={file} alt="establishmentSheetIcon" />,
  link: "/establishment-sheet",
  pathName: "/establishment-sheet",
  id:"establishmentSheet"
};

const profile = {
  name: <IntlMessages id={"sidebar.my.profile"} />,
  type: "item",
  icon: <img src={profileIcon} alt="profileIcon" />,
  link: "/profile",
  pathName: "/my-profile",
  id:"myProfile"
};
const schoolSetting = {
  name: (
    <IntlMessages id={"sidebar.components.school.parameters.classSetting"} />
  ),
  type: "item",
  icon: <img src={classSettings} alt="schoolParametersIcon" />,
  link: "/school-parameters/class-setting",
  pathName: "/school-settings",
  id: "classesParameters",
};
// const connectionAs = {
//   name: <IntlMessages id={"sidebar.sag.connection.as"} />,
//   icon: <img src={iconConnexionETQ} alt="iconConnexionETQ" />,
//   type: "item",
//   link: "/connection-as",
//   pathName: "/login-as",
// };

const supervisor = [
  {
    name: <IntlMessages id={"sidebar.components.timetable"} />,
    type: "item",
    icon: <img src={iconAttendanceTacking} alt="iconAttendanceTacking" />,
    link: "/supervisor/timetable",
    pathName: "/timetable",
    id:"timetable"
  },

  {
    name: <IntlMessages id={"sidebar.components.assiduity"} />,
    type: "item",
    icon: <img src={establishmentSheetIcon} alt="establishmentSheetIcon" />,
    link: "/supervisor/assiduity",
    pathName: "/attendance-tracking",
    id:"assiduity"
  },
  {
    name: <IntlMessages id={"sidebar.components.history.sanctions"} />,
    type: "item",
    icon: <img src={iconSanctionsHistory} alt="iconSanctionsHistory" />,
    link: "/supervisor/history-sanctions",
    pathName: "/sanctions-history",
    id:"historysanctions"
  },
  {
    name: <IntlMessages id={"sidebar.components.school.supplies.management"} />,
    type: "item",
    icon: <img src={iconSchoolSupplies} alt="iconSchoolSupplies" />,
    link: "/supervisor/school-supplies",
    pathName: "/school-supplies",
    id:"schoolsupplies"
  },
];


const cafeteria = [
  {
    name: <IntlMessages id={"sidebar.components.cafeteria.management"} />,
    type: "item",
    icon: <img src={iconCanteenManagement} alt="iconCanteenManagement" />,
    link: "/cafeteria/cafeteria-management",
    pathName: "/canteen-management",
    id:"canteenmanagement"
  },
  {
    name: <IntlMessages id={"sidebar.components.cafeteria.menu.week"} />,
    type: "item",
    icon: <img src={iconMenuWeek} alt="establishmentSheetIcon" />,
    link: "/cafeteria/menu-week",
    pathName: "/weekly-menu",
    id:"weeklymenu"
  },

  {
    name: <IntlMessages id={"sidebar.components.cafeteria.Chef.suggestion"} />,
    type: "item",
    icon: <img src={iconChefSuggestion} alt="iconChefSuggestion" />,
    link: "/cafeteria/Chef-suggestion",
    pathName: "/suggestion-chef",
    id:"suggestionchef"
  },
];
const eLibrary = [
  {
    name: (
      <IntlMessages id={"sidebar.components.eLibrary.multimedias.carriers"} />
    ),
    type: "item",
    icon: <img src={iconMultimediaSupport} alt="iconMultimediaSupport" />,
    link: "/eLibrary/multimedia-support",
    pathName: "/multimedia-carriers",
    id:"multimediasupport"
  },

  {
    name: (
      <IntlMessages id={"sidebar.components.eLibrary.educational.materials"} />
    ),
    type: "item",
    icon: <img src={iconEducationalSupport} alt="iconEducationalSupport" />,
    link: "/eLibrary/educational-support",
    pathName: "/educational-materials",
    id:"educationalmaterials"
  },
];

const evaluations = [
  {
    name: <IntlMessages id={"evaluation.grade.tracking"} />,
    type: "item",
    icon: <img src={iconGradeTracking} alt="iconGradeTracking" />,
    link: "/ratings/garde-tracking",
    pathName: "/grade-tracking",
    id:"gardetracking"
  },
  {
    name: <IntlMessages id={"evaluation.school.reports"} />,
    type: "item",
    icon: <img src={buelltins} alt="iconGradeTracking" />,
    link: "/ratings/school-reports",
    pathName: "/school-reports",
    id:"schoolreports"
  },
  {
    name: <IntlMessages id={"evaluation.grade.config"} />,
    type: "item",
    icon: <img src={iconGradeTracking} alt="iconGradeTracking" />,
    link: "/ratings/grade-config",
    pathName: "/grade-config",
  },
];
const financialMonitoring = [
  {
    name: <IntlMessages id={"financial.monitoring.financial.setup"} />,
    icon: <img src={iconSchoolSetting} alt="iconSchoolSetting" />,
    type: "collapse",
    pathName: "/fiancial-settings",
    children: [
      {
        type: "section",
        children: [
          {
            name: <IntlMessages id={"service.management"} />,
            type: "item",
            icon: <ArrowForward style={{ color: "#5B13B4" }} />,
            link: "/financial-settings/service-management",
          },
          {
            name: <IntlMessages id={"assignment.services"} />,
            type: "item",
            icon: <ArrowForward style={{ color: "#5B13B4" }} />,
            link: "/financial-settings/assignment-services",
          },
        ],
        roleName: "sal",
      },
    ],
  },
  {
    name: <IntlMessages id={"financial.monitoring.list.deadlines"} />,
    type: "item",
    icon: <img src={iconListDeadlines} alt="iconListDeadlines" />,
    link: "/evaluation/list-deadlines",
    pathName: "/list-deadlines",
  },
  {
    name: <IntlMessages id={"financial.monitoring.payment.history"} />,
    type: "item",
    icon: <img src={iconPaymentHistory} alt="iconPaymentHistory" />,
    link: "/evaluation/payment-history",
    pathName: "/payment-history",
  },
];
const HRMonitoring = [
  {
    name: <IntlMessages id={"HR.monitoring.payslips"} />,
    type: "item",
    icon: <img src={iconPayslip} alt="iconPayslip" />,
    link: "/HR-monitoring/payslips",
    pathName: "/payslips",
    id:"payslips"
  },
  {
    name: <IntlMessages id={"HR.monitoring.employee.follow-up"} />,
    type: "item",
    icon: <img src={iconEmployeesTracking} alt="iconEmployeesTracking" />,
    link: "/HR-monitoring/employees-monitoring",
    pathName: "/employees-monitoring",
    id:"employeesmonitoring"
  },
];
const medicalFile = [
  {
    name: <IntlMessages id={"medical.file.vaccination.record"} />,
    type: "item",
    icon: <img src={iconVaccinationCard} alt="iconVaccinationCard" />,
    link: "/medical-file/vaccination-card",
    pathName: "/vaccination-card",
    id:"vaccinationcard"
  },
  {
    name: <IntlMessages id={"medical.file.medical.monitoring"} />,
    type: "item",
    icon: <img src={iconMedicalMonitoring} alt="iconMedicalMonitoring" />,
    link: "/medical-file/medical-monitoring",
    pathName: "/medical-monitoring",
    id:"medicalmonitoringchild"
  },
];
const complaints = [
  {
    name: <IntlMessages id={"dashboard.complaintsList"} />,
    type: "item",
    icon: <img src={iconReclamation} alt="iconReclamation" />,
    link: "/complaints",
    pathName: "/complaints",
    id:"complaintslist"
  },
];
const messaging = [
  {
    name: <IntlMessages id={"sidebar.components.messaging"} />,
    type: "item",
    icon: <img src={iconMessenger} alt="iconMessenger" />,
    link: "/messaging",
    pathName: "/messaging",
    id:"messagingchild"
  },
];
const discussionGroup = [
  {
    name: <IntlMessages id={"sidebar.components.discussion.group"} />,
    type: "item",
    icon: <img src={iconEmployeesTracking} alt="iconEmployeesTracking" />,
    link: "/discussion-group",
    pathName: "/discussion-group",
    id:"discussiongroup"
  },
];
const harassmentAlert = [
  {
    name: <IntlMessages id={"sidebar.components.harassment.alert"} />,
    type: "item",
    icon: <img src={iconHarassmentAlert} alt="iconHarassmentAlert" />,
    link: "/harassment-alert",
    pathName: "/harassment-alert",
    id:"harassmentalertchild"
  },
];
const clubs = [
  {
    name: <IntlMessages id={"appModule.clubs.management"} />,
    type: "item",
    icon: <img src={iconClubManagement} alt="iconClubManagement" />,
    link: "/clubs-management/clubs",
    pathName: "/clubs-management",
    id:"clubsmanagement"
  },
  {
    name: <IntlMessages id={"clubs.newspaper"} />,
    type: "item",
    icon: <img src={iconClubJournal} alt="iconClubJournal" />,
    link: "/clubs-management/clubs-newspaper",
    pathName: "/clubs-newspaper",
    id:"clubsnewspaper"
  },
];


const news = [
  {
    name: <IntlMessages id={"sidebar.components.news"} />,
    type: "item",
    icon: <img src={iconNews} alt="iconNews" />,
    link: "/news",
    pathName: "/news",
    id:"newschild"
  },
];

const eduDrive = [
  {
    name: <IntlMessages id={"sidebar.components.edu.drive"} />,
    type: "item",
    icon: <img src={iconDrive} alt="iconDrive" />,
    link: "/edu-drive/my-drive",
    pathName: "/edu-drive",
    id:"edudrivechild"
  },
];

const reports = [
  {
    name: <IntlMessages id={"app.module.reports"} />,
    type: "item",
    icon: <img src={dashboardIcon} alt="iconReporting" />,
    link: "/reports",
    pathName: "/reports",
    id:"reportschild"
  },
];

const dashboard = [
  {
    name: <IntlMessages id={"sidebar.components.page.home"} />,
    type: "item",
    icon: <img src={iconHome} alt="iconHome" />,
    link: "/dashboard",
    pathName: "/board-table",
  },
];
let newList = [];

export const allSubMenus = newList.concat(
  chauffeurRoutes,
  parkManagementSal,
  realTimeTracking,
  //itineraireManagement,
  eduDrive,
  news,
  clubs,
  harassmentAlert,
  discussionGroup,
  messaging,
  complaints,
  medicalFile,
  HRMonitoring,
  financialMonitoring,
  evaluations,
  eLibrary,
  cafeteria,
  supervisor,
  schoolSetting,
  // connectionAs,
  profile,
  establishmentSheet,
  administrativeDocuments,
  classroomManagement,
  accountManagementSal,
  schoolParametersSal,
  childrenWithSection,
  dashboard,
  reports
);

export const administrationWithSection = {
  name: <IntlMessages id={"sidebar.administration"} />,
  type: "section",
  children: [
    childrenWithSection,
    accountManagementSal,
    schoolSetting,
    classroomManagement,
    administrativeDocuments,
    establishmentSheet,
    profile,
    reports
    // connectionAs,
  ],
  pathName: "/administration",
  id: "administration",
};

export const trackingBusSection = {
  name: <IntlMessages id={"sidebar.components.bus"} />,
  type: "section",
  children: [
    chauffeurRoutes,
    parkManagementSal,
    realTimeTracking,
    //itineraireManagement,

    // connectionAs,
  ],
  pathName: "/bus-tracking",
};
export const administrationWithSectionExpired = {
  name: <IntlMessages id={"sidebar.administration"} />,
  type: "section",
  children: [
    childrenWithSection,
    accountManagementSalExpired,
    schoolSetting,
    classroomManagement,
    administrativeDocuments,
    establishmentSheet,
    profile,
    // connectionAs,
  ],
  pathName: "/administration",
};
export const sidebarNavs = [
  {
    type: "section",
    children: [
      {
        name: <IntlMessages id={"sidebar.components.page.home"} />,
        type: "item",
        icon: <img src={iconHome} alt="iconHome" />,
        link: "/dashboard",
        pathName: "/board-table",
      },
    ],
    pathName: "/board-table",
  },
  {
    name: <IntlMessages id={"sidebar.superadministration"} />,
    type: "section",
    children: superAdminGlobaleMenus,
    pathName: "superadministration",
  },
  {
    name: <IntlMessages id={"sidebar.administration"} />,
    type: "section",
    children: [
      schoolParametersSal,
      accountManagementSal,
      schoolSetting,
      classroomManagement,
      administrativeDocuments,
      establishmentSheet,
      profile,
      // connectionAs,
    ],
    pathName: "/administration",
    id: "administration",
  },
  {
    name: <IntlMessages id={"sidebar.components.supervisor"} />,
    type: "section",
    children: supervisor,
    pathName: "/School-life",
    id: "schoolLife",
  },
  
  {
    name: <IntlMessages id={"sidebar.components.evaluations"} />,
    type: "section",
    children: evaluations,
    pathName: "/evalution",
    id:"evalution"
  },
  {
    name: <IntlMessages id={"sidebar.components.eLibrary"} />,
    type: "section",
    children: eLibrary,
    pathName: "/e-library",
    id:"eLibrary"

  },
  {
    name: <IntlMessages id={"sidebar.components.cafeteria"} />,
    type: "section",
    children: cafeteria,
    pathName: "/canteen",
    id:"canteen"
  },
  {
    name: <IntlMessages id={"sidebar.components.financial.monitoring"} />,
    type: "section",
    children: financialMonitoring,
    pathName: "/financial-settings",
  },
  {
    name: <IntlMessages id={"sidebar.components.HR.monitoring"} />,
    type: "section",
    children: HRMonitoring,
    pathName: "/monitoring-hr",
    id:"monitoringhr"
  },
  {
    name: <IntlMessages id={"sidebar.components.medical.file"} />,
    type: "section",
    children: medicalFile,
    pathName: "/medical-monitoring",
    id:"medicalmonitoring"
  },
  {
    name: <IntlMessages id={"sidebar.components.complaints"} />,
    type: "section",
    children: complaints,
    pathName: "/complaints",
    id:"complaints"
  },
  {
    name: <IntlMessages id={"sidebar.components.messaging"} />,
    type: "section",
    children: messaging,
    pathName: "/messaging",
    id:"messagingparent"
  },
  {
    name: <IntlMessages id={"sidebar.components.discussion.group"} />,
    type: "section",
    children: discussionGroup,
    pathName: "/discussion-group",
    id:"discussiongroupchild"
  },

  {
    name: <IntlMessages id={"sidebar.components.harassment.alert"} />,
    type: "section",
    children: harassmentAlert,
    pathName: "/harassment-alert",
    id:"harassmentalertparent"
  },
  {
    name: <IntlMessages id={"sidebar.components.clubs"} />,
    type: "section",
    children: clubs,
    pathName: "/clubs",
    id:"clubsparent"
  },

  {
    name: <IntlMessages id={"sidebar.components.news"} />,
    type: "section",
    children: news,
    pathName: "/news",
    id:"newsparent"
  },
  {
    name: <IntlMessages id={"sidebar.components.edu.drive"} />,
    type: "section",
    children: eduDrive,
    pathName: "/edu-drive",
    id:"edudriveparent"
  },
  {
    name: <IntlMessages id={"app.module.reports"} />,
    type: "section",
    children: reports,
    pathName: "/reports",
    id:"reportsparent"
  },
  {
    name: <IntlMessages id={"sidebar.components.bus"} />,
    type: "section",
    children: [
      chauffeurRoutes,
      parkManagementSal,
      realTimeTracking,
      // itineraireManagement,

      // connectionAs,
    ],
    pathName: "/bus-tracking",
    id:"bustrackingparent"
  },
];
 
export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={"sidebar.superadministration"} />,
    type: "section",
    children: superAdminGlobaleMenus,
  },
  {
    name: <IntlMessages id={"sidebar.administration"} />,
    type: "section",
    children: [schoolParametersSal, accountManagementSal],
  },
  // {
  //   // name: <IntlMessages id={'sidebar.Apps'} />,
  //   type: "section",
  //   children: managementSag,
  // },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={"sidebar.superadministration"} />,
    type: "section",
    children: superAdminGlobaleMenus,
  },
  {
    name: <IntlMessages id={"sidebar.administration"} />,
    type: "section",
    children: [schoolParametersSal, accountManagementSal],
  },
  // {
  //   // name: <IntlMessages id={'sidebar.Apps'} />,
  //   type: "section",
  //   children: managementSag,
  // },
];
