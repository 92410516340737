import { makeStyles } from "@material-ui/core/styles";
import { THEME_TYPES } from "@jumbo/constants/ThemeOptions";

export const semiDarkTheme = {
  palette: {
    type: THEME_TYPES.LIGHT,
    sidebar: {
      bgColor: "#363636",
      textColor: "rgba(255, 255, 255, 0.3)",
      textDarkColor: "#fff",
      textActiveColor: "#fff",
      navHoverBgColor: "rgba(187, 134, 252, 0.08)",
      navActiveBgColor: "#5b13b4",
      borderColor: "rgba(255, 255, 255, 0.08)",
    },
    horizontalNav: {
      navigationColor: "rgba(255, 255, 255, 0.74)",
      navigationActiveColor: "rgba(255, 255, 255, 1)",
      textColor: "rgba(0, 0, 0, 0.6)",
      textDarkColor: "rgba(0, 0, 0, 0.87)",
      textActiveColor: "#5b13b4",
      menuHoverBgColor: "rgb(229, 229, 229)",
      menuActiveBgColor: "rgb(239, 229, 253)",
    },
    common: {
      dark: "#020202",
    },

    background: {
      paper: "#FFFFFF",
      default: "#f4f4f7",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.3)",
      white: "#fff",
    },
    borderColor: {
      main: "rgba(0, 0, 0, 0.06)",
      dark: "rgba(0, 0, 0, 0.12)",
    },
    lightBtn: {
      bgColor: "#f5f5f5",
      textColor: "rgba(0, 0, 0, 0.38)",
    },
    popupColor: {
      main: "#fff",
    },
  },
  overrides: {
    MuiTab: {
      textColorPrimary: {
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: "#FFFFFF",
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#FFFFFF",
      },
    },
  },
};
export const darkTheme = {
  palette: {
    type: THEME_TYPES.DARK,
    sidebar: {
      bgColor: "#363636",
      textColor: "rgba(255, 255, 255, 0.3)",
      textDarkColor: "#fff",
      textActiveColor: "#fff",
      navHoverBgColor: "rgba(187, 134, 252, 0.08)",
      navActiveBgColor: "#5b13b4",
      borderColor: "rgba(255, 255, 255, 0.08)",
    },
    horizontalNav: {
      navigationColor: "rgba(255, 255, 255, 0.74)",
      navigationActiveColor: "rgba(255, 255, 255, 1)",
      textColor: "rgba(255, 255, 255, 0.3)",
      textDarkColor: "#fff",
      textActiveColor: "#5b13b4",
      menuHoverBgColor: "rgb(0, 0, 0, 0.8)",
      menuActiveBgColor: "rgb(0, 0, 0, 0.5)",
    },
    common: {
      dark: "#fff",
    },
    background: {
      paper: "#121212",
      default: "#2e2e2e",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.87)",
      secondary: "rgba(255, 255, 255, 0.6)",
      disabled: "rgba(255, 255, 255, 0.38)",
      hint: "rgba(255, 255, 255, 0.3)",
      white: "#fff",
    },
    borderColor: {
      main: "rgba(255, 255, 255, 0.06)",
      dark: "rgba(255, 255, 255, 0.12)",
    },
    lightBtn: {
      bgColor: "#535151",
      textColor: "rgba(255, 255, 255, 0.38)",
    },
    popupColor: {
      main: "#363636",
    },
  },
  overrides: {
    MuiTab: {
      textColorPrimary: {
        color: "rgba(255, 255, 255, 0.87)",
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: "#535050",
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#2e2e2e",
      },
    },
  },
};
export const lightTheme = {
  palette: {
    type: THEME_TYPES.LIGHT,
    sidebar: {
      bgColor: "#fff",
      textColor: "rgba(0, 0, 0, 0.6)",
      textDarkColor: "rgba(0, 0, 0, 0.87)",
      textActiveColor: "#5b13b4",
      navHoverBgColor: "rgb(229, 229, 229)",
      navActiveBgColor: "rgb(239, 229, 253)",
      borderColor: "rgba(33, 33, 33, 0.08)",
    },
    horizontalNav: {
      navigationColor: "rgba(255, 255, 255, 0.74)",
      navigationActiveColor: "rgba(255, 255, 255, 1)",
      textColor: "rgba(0, 0, 0, 0.6)",
      textDarkColor: "rgba(0, 0, 0, 0.87)",
      textActiveColor: "#5b13b4",
      menuHoverBgColor: "rgb(229, 229, 229)",
      menuActiveBgColor: "rgb(239, 229, 253)",
    },
    common: {
      dark: "#020202",
    },
    background: {
      paper: "#FFFFFF",
      default: "#f4f4f7",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.3)",
      white: "#fff",
    },
    borderColor: {
      main: "rgba(0, 0, 0, 0.06)",
      dark: "rgba(0, 0, 0, 0.12)",
    },
    lightBtn: {
      bgColor: "#f5f5f5",
      textColor: "rgba(0, 0, 0, 0.38)",
    },
    popupColor: {
      main: "#fff",
    },
  },
  overrides: {
    MuiTab: {
      textColorPrimary: {
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: "#FFFFFF",
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#FFFFFF",
      },
    },
  },
};

export const useStyles1 = makeStyles((theme) => ({
  noticeTypography: {
    fontSize: 13,
    fontWeight: 600,
    color: "grey",
    marginTop: 13,
  },
  gridSubItem: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    color: "#9C9C9C",
    fontWeight: "300",
  },
  iconActionRoot: {
    color: "#5B13B4",
    cursor: "pointer",
  },
  icon: {
    marginRight: 8,
    width: 18,
    height: 14,
  },
  dotIcon: {
    width: "11px",
    height: "11px",
    color: "#FF6E4B",
  },
  iconWrap: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginBottom: 5,
  },
  abscenceCard: {
    boxShadow: "0px 3px 6px #00000029",
  },
  abscenceConfirmQs: {
    marginTop: 30,
    marginBottom: 10,
    color: "#4D4B4B",
    fontWeight: "400",
    textAlign: "center",
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    fontWeight: 200,
  },
  educationSupportList: {
    color: "#5b13b4",
    cursor: "pointer",
  },
  dataList: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: 200,
    color: theme.palette.common.dark,
  },
}));

export const useStyles2 = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#5B13B4",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#5B13B4",
    },
  },
  abscenceConfirmQs: {
    marginTop: 30,
    marginBottom: 10,
    color: "#4D4B4B",
    fontWeight: "400",
    textAlign: "center",
  },
});
