import {
  ADD_BUS,
  GET_BUS,
  DELETE_BUS,
  EDIT_BUS,
  SET_BUS_DETAILS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  buses: [{
    id : 1 ,
    brand : "Mitsubichi" ,
    matricule : "xxxxxxxx",
    capacity : 200,
    state : "En Marche",
    status : "Repos"
  },{
    id : 2 ,
    matricule : "xxxxxxxx",
    brand : "Mazda" ,
    capacity : 200,
    state : "En Marche",
    status : "Repos"
  },{
    id : 3 ,
    brand : "Toyota" ,
    matricule : "xxxxxxxx",
    capacity : 200,
    state : "En Marche",
    status : "Repos"
  },{
    id : 4 ,
    brand : "Marcedes",
    matricule : "xxxxxxxx",
    capacity : 200,
    state : "En Marche",
    status : "Repos"
  },{
    id : 5 ,
    brand : "Marcedes",
    matricule : "xxxxxxxx",
    capacity : 200,
    state : "En Marche",
    status : "Repos"
  },{
    id : 6 ,
    brand : "Hyundai",
    matricule : "xxxxxxxx",
    capacity : 200,
    state : "En Marche",
    status : "Repos"
  },{
    id : 7 ,
    brand : "Hyundai",
    matricule : "xxxxxxxx",
    capacity : 200,
    state : "En Marche",
    status : "Repos"
  },],
  currentBus: null,
};

const Bus = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BUS: {
      return {
        ...state,
        buss: action.payload,
      };
    }
    case ADD_BUS: {
      return {
        ...state,
        buss: [ action.payload, ...state.buss ]
      };
    }
    case EDIT_BUS: {
      return {
        ...state,
        buss: state.buss.map(
          BUS => (BUS.id === action.payload.id ? action.payload : BUS)
        )
      };
    }

    case DELETE_BUS: {
      return {
        ...state,
        buss: state.buss.filter(BUS => BUS.id !== action.payload)
      };
    }

    case SET_BUS_DETAILS: {
      return {
        ...state,
        currentBus: action.payload
      };
    }
    default:
      return state;
  }
};
export default Bus;
