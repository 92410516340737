import React from "react";
import SendIcon from "@material-ui/icons/Send";
import DeleteIcon from "@material-ui/icons/Delete";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import StarIcon from "@material-ui/icons/Star";
import * as moment from "moment";
import inscription from "../assets/images/inscription_scolaire.svg";
import cantine from "../assets/images/cantine.svg";
import excursion from "../assets/images/excursion.svg";
import club_informatique from "../assets/images/club_informatique.svg";
import club_musique from "../assets/images/club_musique.svg";
import bus from "../assets/images/bus.svg";

import studentGirl from "../assets/images/avatarStudentGirl.jpg";
import studentBoy from "../assets/images/studentAvatar.jpg";

import avatarProfWomen from "../assets/images/avatarProfWomen.jpg";
import avatarMan from "../assets/images/avatarMan.jpg";

import classRoom from "../assets/images/avatarClass.jpg";
import avatarClassroom from "../assets/images/avatarClassroom.jpg";

import {
  roleIdSAL,
  roleIdADL,
  roleIdOperator,
  roleIdProfessor,
  roleIdParent,
  roleIdStudent,
} from "./index";
import IntlMessages from "../@jumbo/utils/IntlMessages";
const toDay = new Date();

export const statusDocument = [
  {
    id: "En Cours",
    label: <IntlMessages id={`en.cours.document`} />,
    value: "En Cours",
  },
  {
    id: "Traitée",
    label: <IntlMessages id={`traitee.document`} />,
    value: "Traitée",
  },
  {
    id: "Non Traitée",
    label: <IntlMessages id={`non.traitee.document`} />,
    value: "Non Traitée",
  },
];
export const examTypes = [
  { id: "ordinaryTest", label: "Devoir de contrôle", value: "Dc" },
  { id: "endOfTermTest", label: "Devoir de synthèse", value: "Ds" },
  { id: "TP", label: "Traveaux Pratiques", value: "Tp" },
  { id: "Test", label: "Test d'evaluation", value: "Test" },
  { id: "DF", label: "Devoir Fin trimestre", value: "Df" },
];

export const listRolesUsersForDocument = [
  {
    id: roleIdProfessor,
    label: <IntlMessages id={`role.professor`} />,
    value: roleIdProfessor,
  },
  {
    id: roleIdOperator,
    label: <IntlMessages id={`role.operator`} />,
    value: roleIdOperator,
  },
  {
    id: roleIdStudent,
    label: <IntlMessages id={`role.student`} />,
    value: roleIdStudent,
  },
];

export const listRolesPayslips = [
  {
    id: "admin",
    label: <IntlMessages id={`role.administrative`} />,
    value: "Personnel administratif",
  },
  {
    id: "Professor",
    label: <IntlMessages id={`role.professor`} />,
    value: "Professeur",
  },

  {
    id: "financial",
    label: <IntlMessages id={`role.financial`} />,
    value: "Financier",
  },
  {
    id: "RH",
    label: "Ressource humaine",
    value: "RH",
  },
  {
    id: "Corps médical",
    label: "Corps médical",
    value: "Corps médical",
  },

  {
    id: "Cuisiner",
    label: "Cuisinier",
    value: "Cuisiner",
  },
  {
    id: "Chauffeur de bus",
    label: "Chauffeur de bus",
    value: "Chauffeur de bus",
  },
  {
    id: "Gardien",
    label: "Gardien",
    value: "Gardien",
  },
];
export const listRolesUsers = [
  {
    id: roleIdSAL,
    label: <IntlMessages id={`role.sal`} />,
    value: roleIdSAL,
  },
  {
    id: roleIdADL,
    label: <IntlMessages id={`role.adl`} />,
    value: roleIdADL,
  },
  {
    id: roleIdProfessor,
    label: <IntlMessages id={`role.professor`} />,
    value: roleIdProfessor,
  },
  {
    id: roleIdOperator,
    label: <IntlMessages id={`role.operator`} />,
    value: roleIdOperator,
  },
  {
    id: roleIdParent,
    label: <IntlMessages id={`role.parent`} />,
    value: roleIdParent,
  },
  {
    id: roleIdStudent,
    label: <IntlMessages id={`role.student`} />,
    value: roleIdStudent,
  },
];

export const listRoleParticipants = [
  {
    id: roleIdProfessor,
    label: "Professeur",
    value: roleIdProfessor,
    templateUrl:
      "https://s3.eu-west-3.amazonaws.com/classebook.data.storage/templates-user/Template-import-professor.xlsx",
  },

  {
    id: roleIdParent,
    label: "Parent",
    value: roleIdParent,
    templateUrl:
      "https://educap-storage.s3.eu-west-3.amazonaws.com/Template-import-parent+(1).xlsx",
    templateUrlbasic:
      "https://educap-storage.s3.eu-west-3.amazonaws.com/Template-basic-import-parent.xlsx",
  },
  {
    id: roleIdStudent,
    label: "Élève",
    value: roleIdStudent,
    templateUrl:
      "https://educap-storage.s3.eu-west-3.amazonaws.com/Template-import-student+.xlsx",
    templateUrlbasic:
      "https://educap-storage.s3.eu-west-3.amazonaws.com/Template-import-student-basic.xlsx",
  },
];

export const listRoleETQ = [
  {
    id: roleIdADL,
    label: "Administrateur",
    value: roleIdADL,
  },
  {
    id: roleIdProfessor,
    label: "Professeur",
    value: roleIdProfessor,
  },
  {
    id: roleIdOperator,
    label: "Opérateur",
    value: roleIdOperator,
  },
  {
    id: roleIdParent,
    label: "Parent",
    value: roleIdParent,
  },
  {
    id: roleIdStudent,
    label: "Élève",
    value: roleIdStudent,
  },
];

export const listRoles = [
  {
    id: roleIdADL,
    label: <IntlMessages id={`role.adl`} />,
    value: roleIdADL,
  },
  {
    id: roleIdProfessor,
    label: <IntlMessages id={`role.professor`} />,
    value: roleIdProfessor,
  },
  {
    id: roleIdOperator,
    label: <IntlMessages id={`role.operator`} />,
    value: roleIdOperator,
  },
  {
    id: roleIdStudent,
    label: <IntlMessages id={`role.student`} />,
    value: roleIdStudent,
  },
  {
    id: roleIdParent,
    label: <IntlMessages id={`role.parent`} />,
    value: roleIdParent,
  },
];

export const listRolesWithoutAdl = [
  {
    id: roleIdProfessor,
    label: <IntlMessages id={`role.professor`} />,
    value: roleIdProfessor,
  },
  {
    id: roleIdOperator,
    label: <IntlMessages id={`role.operator`} />,
    value: roleIdOperator,
  },
  {
    id: roleIdStudent,
    label: <IntlMessages id={`role.student`} />,
    value: roleIdStudent,
  },
  {
    id: roleIdParent,
    label: <IntlMessages id={`role.parent`} />,
    value: roleIdParent,
  },
];

export const listRolesWithOutStudent = [
  {
    id: roleIdADL,
    label: <IntlMessages id={`role.adl`} />,
    value: roleIdADL,
  },
  {
    id: roleIdProfessor,
    label: <IntlMessages id={`role.professor`} />,
    value: roleIdProfessor,
  },
  {
    id: roleIdOperator,
    label: <IntlMessages id={`role.operator`} />,
    value: roleIdOperator,
  },
];
export const listRolesWithOutStudentAndAdl = [
  {
    id: roleIdProfessor,
    label: <IntlMessages id={`role.professor`} />,
    value: roleIdProfessor,
  },
  {
    id: roleIdOperator,
    label: <IntlMessages id={`role.operator`} />,
    value: roleIdOperator,
  },
];

export const listTypeDocuments = [
  {
    id: "Attestation d'inscription",
    label: <IntlMessages id={`certificate.of.inscription`} />,
    value: "Attestation d'inscription",
  },
  {
    id: "Attestation de Présence",
    label: <IntlMessages id={`certificate.of.attendance`} />,
    value: "Attestation de Présence",
  },
  {
    id: "Attestation de Réussite",
    label: <IntlMessages id={`certificate.of.achievement`} />,
    value: "Attestation de Réussite",
  },
  {
    id: "Attestation Scolaire",
    label: <IntlMessages id={`certificate.of.scolarity`} />,
    value: "Attestation Scolaire",
  },
  {
    id: "Attestation de Radiation",
    label: <IntlMessages id={`certificate.of.radiation`} />,
    value: "Attestation de Radiation",
  },
  {
    id: "Attestation de Travail",
    label: <IntlMessages id={`work.certificate`} />,
    value: "Attestation de Travail",
  },
];
export const civilityList = [
  // { title: "Mademoiselle", slug: "Mademoiselle" },
  { title: "Madame", slug: "Madame" },
  { title: "Monsieur", slug: "Monsieur" },
];

export const jobList = [
  { title: "Financier", slug: "Financier" },
  { title: "Administratif", slug: "Personnel administratif" },
  { title: "Chauffeur de bus", slug: "Chauffeur de bus" },
  { title: "RH", slug: "RH" },
  { title: "Gardien", slug: "Gardien" },
  { title: "Cuisinier", slug: "Cuisiner" },
  { title: "Corps médical", slug: "Corps médical" },
];
export const bloodGroup = [
  { title: "O+", slug: "O+" },
  { title: "O-", slug: "O-" },
  { title: "A+", slug: "A+" },
  { title: "A-", slug: "A-" },
  { title: "B+", slug: "B+" },
  { title: "B-", slug: "B-" },
  { title: "AB+", slug: "AB+" },
  { title: "AB-", slug: "AB-" },
];
export const communicationChannel = [
  { title: "Téléphone", slug: "Téléphone" },
  { title: "WhatsApp", slug: "WhatsApp" },
];

export const banks = [];
export const currencies = [
  {
    value: "euro",
    name: "€, Euro",
  },
  {
    value: "dollar",
    name: `$, Dollar`,
  },
  {
    value: "GBP",
    name: `£, Livre sterling`,
  },
  {
    value: "TND",
    name: `DT, Dinar tunisian`,
  },
  {
    value: "DZD",
    name: `DA, Dinar algérien`,
  },
  {
    value: "MAD",
    name: `DH, Dirham marocain`,
  },
];
export const systemeEducationList = [
  { title: "Système tunisien ", slug: "système tunisien" },
  { title: "Système français", slug: "système français" },
  { title: "Système americain", slug: "système americain" },
  { title: "International bachelor ", slug: "international bachelor" },
];
export const establishmentTypeList = [
  { title: "Complexe scolaire", slug: "complex" },
  { title: "Etablissement Scolaire simple", slug: "simple" },
];
export const establishmentSingleSchool = [
  { title: "Maternelle", slug: "Maternelle" },
  { title: "Préparatoire", slug: "Préparatoire" },
  { title: "Ecole primaire", slug: "Ecole primaire" },
  { title: "Collège", slug: "Collège" },
  { title: "Lycée ", slug: "Lycée" },
  { title: "Université", slug: "Université" },
];
export const LicenceStatus = [
  { label: "Active", value: "Active" },
  { label: "Bloquée", value: "Bloquée" },
  { label: "Expirée", value: "Expirée" },
  { label: "Essai gratuit", value: "Demo" },
];

export const Paiement = [
  { title: "Annuelle", slug: "Annuel" },
  { title: "Semestriel", slug: "Semestriel" },
  { title: "trimestrielle", slug: "Trimmestriel" },
  { title: "Mensuel", slug: "Mensuel" },
];

export const equipment = [
  { title: "Vidéo Projecteur", slug: "Vidéo Projecteur" },
  { title: "PCs", slug: "PCs" },
  { title: "Board Digital", slug: "Board Digital" },
  { title: "Webcam", slug: "Webcam" },
];

export const classroomTypes = [
  { title: "Salle de cours", slug: "Salle de cours" },
  { title: "Salle TP", slug: "Salle TP" },
  { title: "Laboratoire Sciences", slug: "Laboratoire Sciences" },
  { title: "Laboratoire Physique", slug: "Laboratoire Physique" },
  { title: "Laboratoire Mécanique", slug: "Laboratoire Mécanique" },
  { title: "Laboratoire Électrique", slug: "Laboratoire Électrique" },
  { title: "Laboratoire Informatique", slug: "Laboratoire Informatique" },
  { title: "Salle de Sport", slug: "Salle de Sport" },
  { title: "Salle de Permanance", slug: "Salle de Permanance" },
  { title: "Salle des Professeurs", slug: "Salle des Professeurs" },
];

export const verificationList = [
  { title: "OUI", slug: true },
  { title: "NON", slug: false },
];
export const stateList = [
  { title:  <IntlMessages id="sidebar.components.bus.filter.list.work" />, slug: 'En Marche' },
  { title:  <IntlMessages id="sidebar.components.bus.filter.list.panne" />, slug: 'En Panne' },
];
export const travel_type = [
  { title: "Aller", value: 'outWard' },
  { title: "Retour", value: 'return' },
  { title: "Aller/Retour", value: 'round' }
];
export const trackState 
= [
  { title: "En cours", slug: 'En cours' },
  { title: "Historique", slug: 'Historique' },
];
export const planingState 
= [
  { title: "Aujourd'hui", slug: 'Aujourdhui' },
  { title: "Hier", slug: 'Hier' },
];

export const contractsList = [
  { id: "CDI", label: "CDI" },
  { id: "CDD", label: "CDD" },
  { id: "Contractuel", label: "Contractuel" },
  { id: "CIVP", label: "CIVP" },
];

export const checkListValues = [
  { label: "Oui", id: true },
  { label: "Non", id: false },
];

export const genderList = [
  { label: "Masculin", id: "Man" },
  { label: "Féminin", id: "Woman" },
];

export const nationality = [
  { id: "tunisienne", label: "Tunisienne" },
  { id: "française", label: "Française" },
];

export const vaccineName = [
  { id: "BCG (tuberculose)", label: "BCG (tuberculose)" },
  { id: "Diphtérie", label: "Diphtérie" },
  {
    id: "tétanos et poliomyélite (DTP)",
    label: "tétanos et poliomyélite (DTP)",
  },
  { id: "Coqueluche", label: "Coqueluche" },
  { id: "Fièvre jaune", label: "Fièvre jaune" },
  { id: "Hépatite B", label: "Hépatite B" },
  { id: "Pneumocoque", label: "Pneumocoque" },
  { id: "Méningocoque C", label: "Méningocoque C" },
  { id: "Rougeole", label: "Rougeole" },
  { id: "Oreillon", label: "Oreillon" },
  { id: "Rubéole", label: "Rubéole" },
  { id: "Papillomavirus humains", label: "Papillomavirus humains" },
  { id: "Grippe", label: "Grippe" },
  { id: "Zona", label: "Zona" },
  { id: "Corona", label: "Corona" },
];
export const studentList = [
  { title: "Sabri OUNI", slug: "Sabri OUNI" },
  { title: "Bouraoui KHLIFI", slug: "Bouraoui KHLIFI" },
  { title: "Asma BEN AZZOUZ", slug: "Asma BEN AZZOUZ" },
];

export const frequencyEventList = [
  { id: "Annual", label: <IntlMessages id="event.annual" /> },
  { id: "Weekly", label: <IntlMessages id="event.weekly" /> },
  { id: "Fortnightly", label: <IntlMessages id="event.fortnightly" /> },
];
export const absenceTimeList = [
  { label: <IntlMessages id="app.time.morning" />, id: "Morning" },
  { label: <IntlMessages id="app.time.afterNoon" />, id: "AfterNoon" },
];

export const frequence = [
  { title: "Lundi", slug: "Lundi" },
  { title: "Mardi", slug: "Mardi" },
  { title: "Mercredi", slug: "Mercredi" },
  { title: "Jeudi", slug: "Jeudi" },
  { title: "Vendredi", slug: "Vendredi" },
  { title: "Samedi", slug: "Samedi" },
  
];
export const menuSAG = [
  {
    id: 1,
    nameAr: " مشرف",
    nameEn: "Super Administration",
    nameFr: "Super Administration",
    pathName: "superadministration",
    subModules: [
      {
        id: 1,
        nameFr: "Gestion des établissements",
        nameEn: "Establishment management",
        nameAr: "إدارة المؤسسات",
        pathName: "establishment-management",
      },
      {
        id: 2,
        nameFr: "Gestion des licences",
        nameEn: "License management",
        nameAr: "إدارة ",
        pathName: "license-management",
      },
      {
        id: 3,
        nameFr: "Gestion des SAL",
        nameEn: "Management of SAL",
        nameAr: "إدارة ",
        pathName: "account-management",
      },
      {
        id: 4,
        nameFr: "Connexion ETQ",
        nameEn: "Connection AS ",
        nameAr: "تسجيل دخول",
        pathName: "connection-as",
      },
    ],
  },
];

export const folderList = [
  {
    id: 1,
    name: <IntlMessages id="app.module.messanging.inbox" />,
    slug: "inbox",
    icon: <MoveToInboxIcon style={{ color: "#5B13B4" }} />,
  },
  {
    id: 2,
    name: <IntlMessages id="app.module.messanging.sent.messages" />,
    slug: "sent",
    icon: <SendIcon style={{ color: "#5B13B4" }} />,
  },
  {
    id: 6,
    name: <IntlMessages id="app.module.messanging.delete.messages" />,
    slug: "trash",
    icon: <DeleteIcon style={{ color: "#5B13B4" }} />,
  },
];

export const paymentfrequencyList = [
  { id: "Annual", slug: "Annuel" },
  { id: "Trimester", slug: "Trimestre" },
  { id: "Weekly", slug: "Mensuel" },
  { id: "Daily", slug: "Par jour" },
];
export const monthNames = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Jun",
  "Juillet",
  "Aout",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];
export const hedearToolbar = {
  title: <IntlMessages id="sidebar.components.cafeteria.menu.week" />,
  action: <IntlMessages id="add.new.menu" />,
};

export const servicesManagements = [
  { id: "Inscription scolaire", slug: "Inscription scolaire Mensuel" },
  { id: "Bus", slug: "Bus Mensuel" },
  { id: "Cantine", slug: "Cantine Mensuel" },
  { id: "Club informatique", slug: "Club informatique Mensuel" },
  { id: "Club Musique", slug: "Club Musique Mensuel" },
  { id: "Sortie À Jerba", slug: "Sortie À Jerba Mensuel" },
];

export const filterOptions = [
  {
    id: 1,
    name: <IntlMessages id="app.module.messanging.important" />,
    slug: "important",
    icon: <LabelImportantIcon style={{ color: "#5B13B4" }} />,
  },
  {
    id: 2,
    name: <IntlMessages id="app.module.messanging.favorites" />,
    slug: "favoris",
    icon: <StarIcon style={{ color: "#5B13B4" }} />,
  },
];

export const userFilterList = [
  {
    id: 2,
    name: <IntlMessages id="sag.accounts" />,
    slug: "sal",
    iconColor: "#FF618D",
  },
  {
    id: 4,
    name: <IntlMessages id="operators" />,
    slug: "ope",
    iconColor: "#BF496A",
  },
  {
    id: 5,
    name: <IntlMessages id="dashboard.teacher" />,
    slug: "pro",
    iconColor: "#5B13B4",
  },
  {
    id: 6,
    name: <IntlMessages id="parents" />,
    slug: "par",
    iconColor: "#B8B1F3",
  },
  {
    id: 7,
    name: <IntlMessages id="appModule.students" />,
    slug: "stu",
    iconColor: "#FFC755",
  },
];

export const headCellsDeadlines = [
  {
    id: "studentName",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.settings.student.name" />,
  },
  {
    id: "classroom",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="financial.monitoring.list.deadlines.classroom" />,
  },
  {
    id: "periode",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="financial.monitoring.list.deadlines.periode" />,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="financial.monitoring.list.deadlines.date" />,
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="financial.monitoring.list.deadlines.amount" />,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="financial.monitoring.list.deadlines.status" />,
  },
];
export const hedearToolbarDeadlinesList = {
  title: <IntlMessages id="financial.monitoring.list.deadlines" />,
  selectListTitle: "Période:",
  selectList: [{ value: "Septembre 2022" }, { value: "Octobre 2022" }],
};

export const hedearToolbarPaymentList = {
  title: <IntlMessages id="financial.monitoring.payment.history" />,
};
export const headCellsPayment = [
  {
    id: "lastname",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.LastName" />,
  },
  {
    id: "firstname",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.FirstName" />,
  },
  {
    id: "paymentDate",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="financial.monitoring.payment.paymentDate" />,
  },

  {
    id: "amount",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="financial.monitoring.payment.amount" />,
  },
  {
    id: "paymentMethod",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="financial.monitoring.payment.paymentMethod" />,
  },
];
export const sanctionList = [
  { id: "Avertissement", slug: "Avertissement" },
  { id: "Exclu 2 jours", slug: "Exclu 2 jours" },
  { id: "Exclu temporairement", slug: "Exclu temporairement" },
  { id: "Exclusion définitive", slug: "Exclusion définitive" },
];

export const listRole = [
  {
    id: roleIdProfessor,
    role: "Professeur",
    value: roleIdProfessor,
  },
  {
    id: roleIdOperator,
    role: "Opérateur",
    value: roleIdOperator,
  },
  {
    id: roleIdParent,
    role: "Parent",
    value: roleIdParent,
  },
  {
    id: roleIdStudent,
    role: "Élève",
    value: roleIdStudent,
  },
];

export const typeEventList = [
  {
    id: "Course",
    value: <IntlMessages id="type.course" />,
  },
  {
    id: "Exam",
    value: <IntlMessages id="type.exam" />,
  },
  {
    id: "Event",
    value: <IntlMessages id="type.event" />,
  },
  {
    id: "Appointment",
    value: <IntlMessages id="sidebar.components.make.an.appointment" />,
  },
];

export const JustificationList = [
  {
    id: 1,
    value: "Justifié",
    label: <IntlMessages id="collaborator.absence.list.justification" />,
  },
  {
    id: 2,
    value: "Non justifié",
    label: <IntlMessages id="collaborator.absence.list.No.justification" />,
  },
];
export const typeList = [
  {
    id: 1,
    value: "Congé",
    label: <IntlMessages id="collaborator.absence.list.leave" />,
  },
  {
    id: 2,
    value: "Maladie",
    label: <IntlMessages id="collaborator.absence.list.disease" />,
  },
];
export const listRolesFunctions = [
  {
    id: 1,
    label: "Professeur",
    value: roleIdProfessor,
  },
  {
    id: 2,
    label: "Personnel administratif",
    value: roleIdOperator,
  },
  {
    id: 3,
    label: "Financier",
    value: roleIdOperator,
  },
  {
    id: 4,
    label: "RH",
    value: roleIdOperator,
  },
  {
    id: 5,
    label: "Corps médical",
    value: roleIdOperator,
  },

  {
    id: 6,
    label: "Cuisinier",
    value: roleIdOperator,
  },
  {
    id: 7,
    label: "Chauffeur de bus",
    value: roleIdOperator,
  },
  {
    id: 8,
    label: "Gardien",
    value: roleIdOperator,
  },
];

export const serviceIconList = [
  { id: 0, title: "Inscription scolaire", path: inscription },
  { id: 1, title: "Bus", path: bus },
  { id: 2, title: "Cantine", path: cantine },
  { id: 3, title: "Club informatique", path: club_informatique },
  { id: 4, title: "Club Musique", path: club_musique },
  { id: 5, title: "Sortie À Jerba", path: excursion },
];

export const listAvatarStudent = [
  { src: studentBoy, title: "studentBoy" },
  { src: studentGirl, title: "studentGirl" },
  { src: studentBoy, title: "avatarBoy" },
];

export const listAvatarProfessor = [
  { src: avatarProfWomen, title: "avatarProfWomen" },
  { src: avatarMan, title: "" },
  { src: avatarProfWomen, title: "avatarMan" },
];

export const listAvatarClassroom = [
  { src: classRoom, title: "classRoom" },
  { src: avatarClassroom, title: "avatarClassroom" },
  { src: classRoom, title: "classRoom" },
];
export const headCellsCollaborator = [
  {
    id: "user.functionName",
    withOutSort: false,
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.Function" />,
  },
  {
    id: "lastName",
    withOutSort: true,
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.LastName" />,
  },
  {
    id: "firstName",
    withOutSort: true,
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.FirstName" />,
  },
  {
    id: "date",
    withOutSort: true,
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.date" />,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.establishment.type" />,
  },
  {
    id: "justification",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="assiduity.justification" />,
  },
];

export const sanctiontypes = [
  {
    label: <IntlMessages id="app.module.sanction.type.definitiveExclusion" />,
    value: "Exclu définitivement",
  },
  {
    label: (
      <IntlMessages id="app..module.sanction.type.definitiveExclusionOfAllSchools" />
    ),
    value: "Exclu définitivement de tous les établissements",
  },
  {
    label: <IntlMessages id="app.module.sanction.type.temporaryExclusion" />,
    value: "Exclu temporairement",
  },
  {
    label: <IntlMessages id="app.module.sanction.type.caution" />,
    value: "Avertissement",
  },
  {
    label: <IntlMessages id="app.module.sanction.type.convocation" />,
    value: "Convocation parent",
  },
];
export const exploitable = [
  { label: "Exploitable", value: true },
  { label: "Non exploitable", value: false },
];

export const state = [
  { label: <IntlMessages id="sidebar.components.bus.filter.marche" />, value: "Marche" },
  { label:  <IntlMessages id="sidebar.components.bus.filter.panne" />, value: "Panne" },
  
];

export const headCells = [
  {
    id: "fileName",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.file" />,
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.title" />,
  },
  {
    id: "classroom",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.classroom" />,
  },
];

export const hedearToolbarEducationSupport = {
  title: (
    <IntlMessages id="sidebar.components.eLibrary.educational.materials" />
  ),
  action: (
    <IntlMessages id="sidebar.components.eLibrary.educational.materials.add" />
  ),
};
export const headCellsVaccination = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.students" />,
  },

  {
    id: "className",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.classParams.header" />,
    withOutSort: true,
  },
  {
    id: "vaccinName",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.last.vaccine" />,
  },
  {
    id: "firstDose",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.date.last.vaccine" />,
  },
  {
    id: "secondDose",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.reminder date" />,
  },
];
export const hedearToolbarVaccination = {
  title: <IntlMessages id="appModule.vaccation.record" />,
  action: <IntlMessages id="appModule.vaccation.add" />,
};
export const headCellsComplaints = [
  {
    id: "role",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="user.role" />,
  },
  {
    id: "lastname",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.LastName" />,
  },
  {
    id: "firstname",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.FirstName" />,
  },

  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.establishment.type" />,
  },
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.holiday.date" />,
  },
  {
    id: "hour",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="title.hour" />,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="compalint.status" />,
  },
];
export const headCellsSanction = [
  {
    id: "student",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="dashboard.student" />,
  },

  {
    id: "Classe",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.classParams.header" />,
  },

  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.date" />,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.establishment.type" />,
  },
];
export const hedearToolbarSanction = {
  title: <IntlMessages id="sidebar.components.history.sanctions" />,
  action: <IntlMessages id="appModule.components.history.sanctions.add" />,
};
export const headCellsClassroom = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="title.classroom" />,
  },
  {
    id: "capacityForCourse",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="capacity" />,
  },
  {
    id: "roomType",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.establishment.type" />,
  },
  {
    id: "examRoom",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="examen.classroom" />,
  },
];
export const headCellsByClass = [
  {
    id: "lastName",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.LastName" />,
  },
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.FirstName" />,
  },
  {
    id: "Notes",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="Notes.label" />,
  },
];
export const headCellsNews = [
  {
    id: "photo",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.tableHeader.canteen.picture" />,
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.title" />,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.date" />,
  },
  {
    id: "hour",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="title.hour" />,
  },
  {
    id: "contents",
    numeric: true,
    disablePadding: false,
    label: <IntlMessages id="news.contents" />,
  },
];
export const hedearToolbarNews = {
  title: <IntlMessages id="dashboard.actualities" />,
  action: <IntlMessages id="add.new.news" />,
};
export const headCellsSupplies = [
  {
    id: "classrooms",
    withOutSort: true,
    label: <IntlMessages id="appModule.classroom" />,
  },
  {
    id: "publicationDate",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.publicationDate" />,
  },
  {
    withOutSort: true,
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.supplies" />,
  },
];
export const headCellsCafeteria = [
  {
    id: "picture",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.tableHeader.canteen.picture" />,
  },
  {
    id: "dishName",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.tableHeader.canteen.dishName" />,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.establishment.type" />,
  },
  {
    id: "ingrediants",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.tableHeader.canteen.ingrediants" />,
  },
];
export const headCellsSuggestion = [
  {
    id: "currentDate",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.suggestionChef.dateCreation" />,
  },
  {
    id: "suggestionDate",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.suggestionChef.dateDemande" />,
  },
  {
    id: "message",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.suggestionChef.label" />,
  },
];
export const hedearToolbarSuggestion = {
  title: <IntlMessages id="appModule.suggestionChef" />,
  action: <IntlMessages id="appModule.suggestionChef.add" />,
};
export const hedearToolbarManagementCafeteria = {
  title: <IntlMessages id="appModule.tableHeader.canteen.title" />,
  action: <IntlMessages id="appModule.tableHeader.canteen.addNewDish" />,
};
export const headCellsPayslip = [
  {
    id: "lastName",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="assiduity.nameAndSurname" />,
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.Function" />,
  },
  {
    id: "month",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.months" />,
  },
  {
    id: "payslip",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="HR.monitoring.payslips" />,
  },
];
export const headCellsReportCard = [
  {
    id: "lastName",
    numeric: false,
    withOutSort: true,
    disablePadding: true,
    label: <IntlMessages id="appModule.LastName" />,
  },
  {
    id: "firstName",
    numeric: false,
    withOutSort: true,
    disablePadding: true,
    label: <IntlMessages id="appModule.FirstName" />,
  },
  {
    id: "classroom",
    numeric: false,
    withOutSort: true,
    disablePadding: false,
    label: <IntlMessages id="appModule.classroom" />,
  },
  {
    id: "schoolSession",
    numeric: false,
    withOutSort: true,
    disablePadding: false,
    label: <IntlMessages id="appModule.session.header" />,
  },
  {
    id: "Bulletin",
    numeric: false,
    withOutSort: true,
    disablePadding: false,
    label: <IntlMessages id="appModule.school.reports" />,
  },
];
export const dateRange = (startDate, endDate) => {
  var start = startDate?.split("-");
  var end = endDate?.split("-");
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? "0" + month : month;
      dates.push({ label: [i, displayMonth, "01"].join("-").slice(0, -3) });
    }
  }
  return dates;
};
export const hedearToolbarCollaborator = {
  title: <IntlMessages id="HR.monitoring.employee.follow-up" />,
  action: <IntlMessages id="HR.monitoring.employee.follow-up.add" />,
  selectList: [
    {
      label:
        "Janvier " +
        moment(toDay)
          .format()
          .slice(0, 4),
      value:
        moment(toDay)
          .format()
          .slice(0, 4) + "-01",
    },
    {
      label:
        "Février " +
        moment(toDay)
          .format()
          .slice(0, 4),
      value:
        moment(toDay)
          .format()
          .slice(0, 4) + "-02",
    },
    {
      label:
        "Mars " +
        moment(toDay)
          .format()
          .slice(0, 4),
      value:
        moment(toDay)
          .format()
          .slice(0, 4) + "-03",
    },
    {
      label:
        "Avril " +
        moment(toDay)
          .format()
          .slice(0, 4),
      value:
        moment(toDay)
          .format()
          .slice(0, 4) + "-04",
    },
    {
      label:
        "Mai " +
        moment(toDay)
          .format()
          .slice(0, 4),
      value:
        moment(toDay)
          .format()
          .slice(0, 4) + "-05",
    },
    {
      label:
        "Juin " +
        moment(toDay)
          .format()
          .slice(0, 4),
      value:
        moment(toDay)
          .format()
          .slice(0, 4) + "-06",
    },
    {
      label:
        "Juillet " +
        moment(toDay)
          .format()
          .slice(0, 4),
      value:
        moment(toDay)
          .format()
          .slice(0, 4) + "-07",
    },
    {
      label:
        "Août " +
        moment(toDay)
          .format()
          .slice(0, 4),
      value:
        moment(toDay)
          .format()
          .slice(0, 4) + "-08",
    },
    {
      label:
        "Septembre " +
        moment(toDay)
          .format()
          .slice(0, 4),
      value:
        moment(toDay)
          .format()
          .slice(0, 4) + "-09",
    },
    {
      label:
        "Octobre " +
        moment(toDay)
          .format()
          .slice(0, 4),
      value:
        moment(toDay)
          .format()
          .slice(0, 4) + "-10",
    },
    {
      label:
        "Novembre " +
        moment(toDay)
          .format()
          .slice(0, 4),
      value:
        moment(toDay)
          .format()
          .slice(0, 4) + "-11",
    },
    {
      label:
        "Décembre " +
        moment(toDay)
          .format()
          .slice(0, 4),
      value:
        moment(toDay)
          .format()
          .slice(0, 4) + "-12",
    },
  ],
};

export const pathRole = {
  2: "superadmin",
  3: "admin",
  4: "operator",
};
export const pathRoleNames = {
  2: <IntlMessages id="role.sal" />,
  3: <IntlMessages id="role.adl" />,
  4: <IntlMessages id="role.operator" />,
};
export const headCellsMedical = [
  {
    id: "student",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="dashboard.student" />,
  },
  {
    id: "className",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.classroom" />,
  },
  {
    id: "actionDate",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.date" />,
  },
  {
    id: "actionDesc",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.description" />,
  },
];
export const hedearToolbarMedical = {
  title: <IntlMessages id="appModule.medicalMonitoring" />,
  action: <IntlMessages id="appModule.medicalMonitoring.add" />,
};
export const headCellsAssiduity = [
  {
    id: "studentName",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="dashboard.student" />,
  },

  {
    id: "subject",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.subject.header" />,
  },
  {
    id: "abscenceDate",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.date" />,
  },
  {
    id: "session",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="assiduity.session" />,
  },

  {
    id: "justification",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="assiduity.status" />,
  },
];
export const hedearToolbarAssiduity = {
  title: <IntlMessages id="sidebar.components.assiduity" />,
};

export const typeListCanteen = [
  {
    title: <IntlMessages id="app.module.canteen.dish.start.plat" />,
    slug: "Entrée",
  },
  {
    title: <IntlMessages id="app.module.canteen.dish.suit.plat" />,
    slug: "Suite",
  },
  {
    title: <IntlMessages id="app.module.canteen.dish.dessert.plat" />,
    slug: "Dessert",
  },
];
export const hedearToolbarPayslips = {
  title: <IntlMessages id="appModule.payslips" />,
  action: <IntlMessages id="appModule.payslips.add" />,
};
export const hedearToolbarMedia = {
  title: <IntlMessages id="sidebar.components.eLibrary.multimedias.carriers" />,
  action: (
    <IntlMessages id="sidebar.components.eLibrary.multimedia.carriers.add" />
  ),
};
export const hedearToolbarMediaView = {
  title: <IntlMessages id="sidebar.components.eLibrary.multimedia.carriers" />,
};
export const hedearToolbarPedagogique = {
  title: (
    <IntlMessages id="sidebar.components.eLibrary.educational.materials" />
  ),
};
export const hedearToolbarReports = {
  title: <IntlMessages id="sidebar.components.school.reports" />,
  action: <IntlMessages id="sidebar.components.school.reports.add" />,
};
export const headCellsByClassReports = [
  {
    id: "lastName",
    numeric: false,

    disablePadding: true,
    label: <IntlMessages id="appModule.LastName" />,
  },
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.FirstName" />,
  },
  {
    id: "average",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.school.reports.average" />,
  },
];
export const hedearToolbarDetailsReports = {
  title: <IntlMessages id="sidebar.components.school.reports" />,
};
export const hedearToolbarSuppliesAdd = {
  title: <IntlMessages id="sidebar.components.school.supplies" />,
  action: <IntlMessages id="sidebar.components.school.supplies.add" />,
};
export const hedearToolbarSupplies = {
  title: <IntlMessages id="sidebar.components.school.supplies" />,
};
export const headCellsHarassement = [
  {
    id: "student",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="dashboard.parentName" />,
  },
  {
    id: "className",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="dashboard.student" />,
  },
  {
    id: "actionDate",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.context" />,
  },
  {
    id: "message",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.suggestionChef.label" />,
  },
];
export const hedearToolbarHarassement = {
  title: <IntlMessages id="appModule.harassement" />,
};
export const hedearToolbarPayslip = {
  title: <IntlMessages id="appModule.payslips" />,
};
export const absenceList = [
  { label: "Absent" },
  { label: "Présent" },
  { label: "Retard" },
  { label: "Exclu" },
];
export const videoConferenceType = [
  { id: "zoom", label: "Zoom" },
  { id: "jitsi", label: "Jitsi" },
  { id: "chime", label: "Chime" },
];
export const hedearToolbarComplaints = {
  title: <IntlMessages id="sidebar.components.complaint" />,
};
export const headCellsUsers = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.FirstName" />,
  },
  {
    id: "lastName",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.LastName" />,
  },
  {
    id: "userName",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.identifiant" />,
  },
  {
    id: "roleId",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="user.role" />,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.information.email" />,
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: <IntlMessages id="appModule.information.phoneNumber" />,
  },
];
export const headCellsClassroomsImport = [
  {
    id: "rowNumber",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="row.number" />,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="row.status" />,
  },
  {
    id: "messageError",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.suggestionChef.label" />,
  },
];
export const headCellsGrade = [
  {
    id: "student",
    withOutSort: true,
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="dashboard.student" />,
  },
  {
    id: "subject",
    withOutSort: true,
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.subject.header" />,
  },
  {
    id: "professor",
    withOutSort: true,
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="role.professor" />,
  },
  {
    id: "date",
    withOutSort: true,
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.date" />,
  },
  {
    id: "Moyenne",
    withOutSort: true,
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="Moyenne.class" />,
  },
  {
    id: "Notes",
    numeric: false,
    withOutSort: true,
    disablePadding: false,
    label: <IntlMessages id="Notes.label" />,
  },
];
export const hedearToolbarGrade = {
  title: <IntlMessages id="evaluation.grade.tracking" />,
  action: <IntlMessages id="sidebar.components.notes.add" />,
};
export const headCellsBus = [
  {
    id: "busNumber",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.busNumber" />,
  },
  {
    id: "matricule",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.matricule" />,
  },
  {
    id: "capacity",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.capacity" />,
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.state" />,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.licence.status" />,
  },
  
];

export const headCellsItineraire = [
  {
    id: "itineraireNumber",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id= "itineraire.itineraireNumber" />,
  },
  {
    id: "nomItineraire",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id= "itineraire.itineraireName"/>,
  },
  {
    id: "numBus",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.busNumber" />,
  },
  {
    id: "chauffeur",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="itineraire.driver"/>,
  },
  
  
];
export const trackingUserEduDrive=[
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="assiduity.nameAndSurname" />,
  },
  {
    id: "storage",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="app.module.edu.drive.storage" />,
  },
]  



