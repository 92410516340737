import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import IntlMessages from "@jumbo/utils/IntlMessages";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  formControlRoot: {
    "& label.Mui-focused": {
      color: "#5B13B46E",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#C4C4C4",
      },
      "&:hover fieldset": {
        borderColor: "#5B13B46E",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#5B13B46E",
      },
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: 10.5,
      paddingBottom: 10.5,
      backgroundColor: "transparent",

      borderRadius: 4,
    },
    "& .MuiSelect-icon ": {
      color: "#5b13b4",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[400],
    },
  },

  tr: {
    "&:hover,&:focus": {
      backgroundColor: theme.palette.purple,
    },
  },
}));

const AppSelectBox = (props) => {
  const classes = useStyles(props);
  const {
    autoWidth,
    data,
    renderRow,
    placeholder,
    name,
    id,
    fullWidth,
    displayEmpty,
    size,
    value,
    required,
    onChange,
    helperText,
    variant,
    error,
    valueKey,
    labelKey,
    backgroundColor,
    label,
    className,
    customInput,
    selectAll,
    defaultValue,
    ...rest
  } = props;

  console.log("data", data);

  return (
    <FormControl
      variant={variant}
      size={size}
      autoWidth={autoWidth}
      fullWidth={fullWidth}
      required={required}
      className={clsx(classes.formControlRoot, "form-control")}
    >
      {label && <InputLabel id={"label-" + id || name}>{label}</InputLabel>}

      <Select
        {...rest}
        name={name}
        labelId={"label-" + id || name}
        id={id || name}
        value={value}
        autoWidth={autoWidth}
        defaultValue={defaultValue}
        onChange={onChange}
        label={label ? (required ? label + "*" : label) : null}
        error={error || helperText !== ""}
        displayEmpty={displayEmpty}
        className={classes.select}
      >
        {customInput}
        {placeholder && displayEmpty && (
          <MenuItem value="">{placeholder}</MenuItem>
        )}
        {selectAll && (
          <MenuItem key={0} value={"select-all"}>
            <em>
              <IntlMessages id="appModule.all" />
            </em>
          </MenuItem>
        )}
        {!_.isEmpty(data) ? (
          data?.map((item, index) =>
            renderRow ? (
              renderRow(item, index)
            ) : (
              <MenuItem
                key={index}
                value={valueKey ? item[valueKey] : item}
                className={clsx(classes.tr)}
              >
                {item[labelKey]}
              </MenuItem>
            )
          )
        ) : (
          <MenuItem disabled>
            {" "}
            <IntlMessages id="empty.list" />
          </MenuItem>
        )}
      </Select>
      {helperText && (
        <FormHelperText error={error || helperText !== ""}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

AppSelectBox.prototype = {
  data: PropTypes.array.isRequired,
  renderRow: PropTypes.node,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  backgroundColor: PropTypes.string,
  customInput: PropTypes.element,
  selectAll: PropTypes.bool,
};

AppSelectBox.defaultProps = {
  data: [],
  fullWidth: true,
  displayEmpty: true,
  required: false,
  size: "small",
  error: false,
  helperText: "",
  valueKey: "id",
  labelKey: "title",
  backgroundColor: "transparent",
  selectAll: false,
};

export default AppSelectBox;
