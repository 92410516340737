export const AppHeaderHeight = 72;
export const AppHeaderHeightSm = 64;
export const AppFooterHeight = 72;
export const AppFooterHeightSm = 64;
export const AppMainContentPadding = 60;
export const InBuildAppHeaderHeight = 70;
export const InBuildAppSidebarHeaderHeight = 86;
export const MailAppContentHeaderHeight = 78;
export const CurrentAuthMethod = "jwtAuth"; //jwtAuth,firebase,basic

export const getAppSidebarHeight = (width, visibleAppFooter) => {
  switch (width) {
    case "xs":
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        InBuildAppSidebarHeaderHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    case "sm":
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        InBuildAppSidebarHeaderHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    default:
      return (
        AppHeaderHeight +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        InBuildAppSidebarHeaderHeight +
        (visibleAppFooter ? AppHeaderHeight : 0)
      );
  }
};

export const getMailContainerHeight = (width, visibleAppFooter) => {
  switch (width) {
    case "xs":
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        MailAppContentHeaderHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    case "sm":
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        MailAppContentHeaderHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    default:
      return (
        AppHeaderHeight +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        MailAppContentHeaderHeight +
        (visibleAppFooter ? AppFooterHeight : 0)
      );
  }
};
